.popularPlanBox {
  border-radius: 4px;
  background: #ecf6ff;
  padding: 10px;
  color: #096dd9;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100%;
}
.planMainCard {
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  padding: 15px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__heading {
    margin-bottom: 35px;
    text-align: center;
  }
  &__title {
    color: rgba(0, 0, 0, 0.85);
    font-family: "Roboto", sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 10px;
  }
  &__price {
    color: #096dd9;
    font-family: "Roboto", sans-serif;
    font-size: 26px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.78px;
  }
  &__footer {
    text-align: center;
    padding: 10px 0;
    button {
      border-radius: 4px;
      // border: 1px solid #096dd9 !important;
      background: #096dd9 !important;
      padding: 8px 24px;
      color: #fff !important;
      text-align: center;
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      margin-right: 0px;
      margin-left: 0px;
      &.selectedPlan {
        border: 1px solid #ecf6ff !important;
        background: #ecf6ff !important;
        color: #000 !important;
      }
      &.downGrad {
        background: #fff !important;
        color: #096dd9 !important;
      }
      &.requestReceived {
        background: #02c44f !important;
      }
    }
  }
  &__list {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 15px;
    &-item {
      color: #3d3c41;
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.54px;
      display: flex;
      gap: 8px;
      span {
        font-size: 14px;
        letter-spacing: -0.42px;
      }
      svg {
        height: 18px;
        width: 18px;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 18px;
      }
    }
  }
}
