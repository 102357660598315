.resourceHeader{
  display: flex;
}
.resource {
  .documentCard {
    .documentCard__img {
      padding: 25px 25px 0;

      .documentCard__img-card {
        padding: 13px 25px 0;

        .version {
          color: rgba(60, 60, 67, 0.6);
          font-weight: 400;
          font-size: 14px;
          margin-top: 35px;
          position: absolute;
          top: 43px;
        }

        .updateAt {
          color: rgba(60, 60, 67, 0.6);
          font-weight: 400;
          font-size: 14px;
          margin-top: 35px;
          position: absolute;
          top: 63px;
        }
      }
    }
    .documentCard__btn {
      padding: 10px 2px;

      span {
        color: #096dd9;
        background-color: #fff;
        font-weight: 700;
        display: flex;
        gap: 8px;
      }
      img {
        height: 16px;
        width: 16px;
      }
    }
  }
  // .resourceForm_cards:nth-of-type(odd) .documentCard {
  //   .documentCard__img {
  //     background-color: #e4dff2;
  //   }
  // }

  // .resourceForm_cards:nth-of-type(even) .documentCard {
  //   .documentCard__img {
  //     background-color: #d9faea;
  //   }
  // }
  // .resourceMessage_cards:nth-of-type(odd) .documentCard {
  //   .documentCard__img {
  //     background-color: #d9faea;
  //   }
  // }

  // .resourceMessage_cards:nth-of-type(even) .documentCard {
  //   .documentCard__img {
  //     background-color: #e4dff2;
  //   }
  // }
}
.provider{
  background-color: #e4dff2;
}
.client{
  background-color: #d9faea;
}
.resourceHub-preview {
  .MuiDialog-paper {
    overflow: hidden !important;
  }
  .sv-components-column {
    max-height: 500px;
    overflow: auto;
  }
  .sd-body.sd-body--static {
    height: 500px;
  }
}

.template-preview {
  padding: 30px;
  .content {
    display: grid;
    grid-template-columns: 1fr 9fr;
    gap: 20px;
  }
  .centeredText {
    display: flex;
    justify-content: center;
    align-items: center;
    // height: 40px;
    max-width: max-content;
  }
  .message {
    // box-sizing: border-box;
    // box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    // padding: 15px;
    // overflow: auto;
    // margin-bottom: 30px;
    p {
      line-height: 20px !important;
    }
  }
  .card {
    background: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    padding: 10px 15px;
    box-shadow: none;
    font-family: "Roboto", sans-serif;
    color: rgba(0, 0, 0, 0.85);
    margin: 4px 0;
  }
  .label {
    font-weight: bold;
    margin: 10px 0px;
  }
}
