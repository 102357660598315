.svc-creator {
  --lbr-line-height-x8: calc(var(--lbr-line-height-unit, 8px) * 8);
  --lbr-line-height-x7: calc(var(--lbr-line-height-unit, 8px) * 7);
  --lbr-line-height-x6: calc(var(--lbr-line-height-unit, 8px) * 6);
  --lbr-line-height-x5: calc(var(--lbr-line-height-unit, 8px) * 5);
  --lbr-line-height-x4: calc(var(--lbr-line-height-unit, 8px) * 4);
  --lbr-line-height-x3: calc(var(--lbr-line-height-unit, 8px) * 3);
  --lbr-line-height-x2: calc(var(--lbr-line-height-unit, 8px) * 2);
  --lbr-line-height-x150: calc(var(--lbr-line-height-unit, 8px) * 1.5);
  --lbr-line-height-x1: calc(var(--lbr-line-height-unit, 8px) * 1);
  --lbr-font-size-x8: calc(var(--lbr-font-unit, 8px) * 8);
  --lbr-font-size-x7: calc(var(--lbr-font-unit, 8px) * 7);
  --lbr-font-size-x6: calc(var(--lbr-font-unit, 8px) * 6);
  --lbr-font-size-x5: calc(var(--lbr-font-unit, 8px) * 5);
  --lbr-font-size-x4: calc(var(--lbr-font-unit, 8px) * 4);
  --lbr-font-size-x3: calc(var(--lbr-font-unit, 8px) * 3);
  --lbr-font-size-x2: calc(var(--lbr-font-unit, 8px) * 2);
  --lbr-font-size-x1: calc(var(--lbr-font-unit, 8px) * 1);
  --lbr-font-size-x150: calc(var(--lbr-font-unit, 8px) * 1.5);
}

.svc-creator {
  --lbr-spacing-x16: calc(var(--lbr-spacing-unit, 8px) * 16);
  --lbr-spacing-x15: calc(var(--lbr-spacing-unit, 8px) * 15);
  --lbr-spacing-x14: calc(var(--lbr-spacing-unit, 8px) * 14);
  --lbr-spacing-x13: calc(var(--lbr-spacing-unit, 8px) * 13);
  --lbr-spacing-x12: calc(var(--lbr-spacing-unit, 8px) * 12);
  --lbr-spacing-x11: calc(var(--lbr-spacing-unit, 8px) * 11);
  --lbr-spacing-x1050: calc(var(--lbr-spacing-unit, 8px) * 10.5);
  --lbr-spacing-x10: calc(var(--lbr-spacing-unit, 8px) * 10);
  --lbr-spacing-x9: calc(var(--lbr-spacing-unit, 8px) * 9);
  --lbr-spacing-x850: calc(var(--lbr-spacing-unit, 8px) * 8.5);
  --lbr-spacing-x8: calc(var(--lbr-spacing-unit, 8px) * 8);
  --lbr-spacing-x7: calc(var(--lbr-spacing-unit, 8px) * 7);
  --lbr-spacing-x6: calc(var(--lbr-spacing-unit, 8px) * 6);
  --lbr-spacing-x5: calc(var(--lbr-spacing-unit, 8px) * 5);
  --lbr-spacing-x4: calc(var(--lbr-spacing-unit, 8px) * 4);
  --lbr-spacing-x350: calc(var(--lbr-spacing-unit, 8px) * 3.5);
  --lbr-spacing-x3: calc(var(--lbr-spacing-unit, 8px) * 3);
  --lbr-spacing-x2: calc(var(--lbr-spacing-unit, 8px) * 2);
  --lbr-spacing-x1: calc(var(--lbr-spacing-unit, 8px) * 1);
  --lbr-spacing-x0375: calc(var(--lbr-spacing-unit, 8px) * 0.375);
  --lbr-spacing-x05: calc(var(--lbr-spacing-unit, 8px) * 0.5);
  --lbr-spacing-x250: calc(var(--lbr-spacing-unit, 8px) * 2.5);
  --lbr-spacing-x150: calc(var(--lbr-spacing-unit, 8px) * 1.5);
  --lbr-spacing-x125: calc(var(--lbr-spacing-unit, 8px) * 1.25);
  --lbr-spacing-x075: calc(var(--lbr-spacing-unit, 8px) * 0.75);
  --lbr-spacing-x025: calc(var(--lbr-spacing-unit, 8px) * 0.25);
  --lbr-spacing-x0125: calc(var(--lbr-spacing-unit, 8px) * 0.125);
  --lbr-spacing-x025n: calc(var(--lbr-spacing-unit, 8px) * -0.25);
  --lbr-spacing-x05n: calc(var(--lbr-spacing-unit, 8px) * -0.5);
  --lbr-spacing-x1n: calc(var(--lbr-spacing-unit, 8px) * -1);
  --lbr-spacing-x150n: calc(var(--lbr-spacing-unit, 8px) * -1.5);
  --lbr-spacing-x2n: calc(var(--lbr-spacing-unit, 8px) * -2);
  --lbr-spacing-x250n: calc(var(--lbr-spacing-unit, 8px) * -2.5);
  --lbr-spacing-x3n: calc(var(--lbr-spacing-unit, 8px) * -3);
  --lbr-spacing-x4n: calc(var(--lbr-spacing-unit, 8px) * -4);
  --lbr-spacing-x5n: calc(var(--lbr-spacing-unit, 8px) * -5);
  --lbr-spacing-x6n: calc(var(--lbr-spacing-unit, 8px) * -6);
  --lbr-spacing-x7n: calc(var(--lbr-spacing-unit, 8px) * -7);
  --lbr-spacing-x8n: calc(var(--lbr-spacing-unit, 8px) * -8);
  --lbr-corner-radius-x0125: calc(var(--lbr-corner-radius-unit, 8px) * 0.125);
  --lbr-corner-radius-x025: calc(var(--lbr-corner-radius-unit, 8px) * 0.25);
  --lbr-corner-radius-x0375: calc(var(--lbr-corner-radius-unit, 8px) * 0.375);
  --lbr-corner-radius-x05: calc(var(--lbr-corner-radius-unit, 8px) * 0.5);
  --lbr-corner-radius-x075: calc(var(--lbr-corner-radius-unit, 8px) * 0.75);
  --lbr-corner-radius-x1: calc(var(--lbr-corner-radius-unit, 8px) * 1);
  --lbr-corner-radius-x125: calc(var(--lbr-corner-radius-unit, 8px) * 1.25);
  --lbr-corner-radius-x150: calc(var(--lbr-corner-radius-unit, 8px) * 1.5);
  --lbr-corner-radius-x2: calc(var(--lbr-corner-radius-unit, 8px) * 2);
  --lbr-corner-radius-x250: calc(var(--lbr-corner-radius-unit, 8px) * 2.5);
  --lbr-corner-radius-x3: calc(var(--lbr-corner-radius-unit, 8px) * 3);
  --lbr-corner-radius-x350: calc(var(--lbr-corner-radius-unit, 8px) * 3.5);
  --lbr-corner-radius-x4: calc(var(--lbr-corner-radius-unit, 8px) * 4);
  --lbr-corner-radius-x5: calc(var(--lbr-corner-radius-unit, 8px) * 5);
  --lbr-corner-radius-x6: calc(var(--lbr-corner-radius-unit, 8px) * 6);
  --lbr-corner-radius-x7: calc(var(--lbr-corner-radius-unit, 8px) * 7);
  --lbr-corner-radius-x8: calc(var(--lbr-corner-radius-unit, 8px) * 8);
  --lbr-corner-radius-round: 1024px;
  --lbr-stroke-x1: calc(var(--lbr-stroke-unit, 1px) * 1);
  --lbr-stroke-x2: calc(var(--lbr-stroke-unit, 1px) * 2);
  --lbr-stroke-x3: calc(var(--lbr-stroke-unit, 1px) * 3);
  --lbr-stroke-x4: calc(var(--lbr-stroke-unit, 1px) * 4);
  --lbr-stroke-x6: calc(var(--lbr-stroke-unit, 1px) * 6);
  --lbr-stroke-x8: calc(var(--lbr-stroke-unit, 1px) * 8);
  --lbr-size-x0125: calc(var(--lbr-size-unit, 8px) * 0.125);
  --lbr-size-x025: calc(var(--lbr-size-unit, 8px) * 0.25);
  --lbr-size-x05: calc(var(--lbr-size-unit, 8px) * 0.5);
  --lbr-size-x075: calc(var(--lbr-size-unit, 8px) * 0.75);
  --lbr-size-x1: calc(var(--lbr-size-unit, 8px) * 1);
  --lbr-size-x125: calc(var(--lbr-size-unit, 8px) * 12.5);
  --lbr-size-x150: calc(var(--lbr-size-unit, 8px) * 1.5);
  --lbr-size-x2: calc(var(--lbr-size-unit, 8px) * 2);
  --lbr-size-x250: calc(var(--lbr-size-unit, 8px) * 2.5);
  --lbr-size-x3: calc(var(--lbr-size-unit, 8px) * 3);
  --lbr-size-x350: calc(var(--lbr-size-unit, 8px) * 3.5);
  --lbr-size-x4: calc(var(--lbr-size-unit, 8px) * 4);
  --lbr-size-x450: calc(var(--lbr-size-unit, 8px) * 4.5);
  --lbr-size-x5: calc(var(--lbr-size-unit, 8px) * 5);
  --lbr-size-x550: calc(var(--lbr-size-unit, 8px) * 5.5);
  --lbr-size-x6: calc(var(--lbr-size-unit, 8px) * 6);
  --lbr-size-x7: calc(var(--lbr-size-unit, 8px) * 7);
  --lbr-size-x8: calc(var(--lbr-size-unit, 8px) * 8);
  --lbr-size-x9: calc(var(--lbr-size-unit, 8px) * 9);
  --lbr-size-x10: calc(var(--lbr-size-unit, 8px) * 10);
  --lbr-size-x11: calc(var(--lbr-size-unit, 8px) * 11);
  --lbr-size-x12: calc(var(--lbr-size-unit, 8px) * 12);
  --lbr-size-x13: calc(var(--lbr-size-unit, 8px) * 13);
  --lbr-size-x14: calc(var(--lbr-size-unit, 8px) * 14);
  --lbr-size-x15: calc(var(--lbr-size-unit, 8px) * 15);
  --lbr-size-x16: calc(var(--lbr-size-unit, 8px) * 16);
  --lbr-size-x17: calc(var(--lbr-size-unit, 8px) * 17);
  --lbr-size-x18: calc(var(--lbr-size-unit, 8px) * 18);
  --lbr-size-x20: calc(var(--lbr-size-unit, 8px) * 20);
  --lbr-size-x28: calc(var(--lbr-size-unit, 8px) * 28);
  --lbr-size-x32: calc(var(--lbr-size-unit, 8px) * 32);
  --lbr-size-x40: calc(var(--lbr-size-unit, 8px) * 40);
  --lbr-size-x50: calc(var(--lbr-size-unit, 8px) * 50);
  --lbr-size-x60: calc(var(--lbr-size-unit, 8px) * 60);
  --lbr-size-x64: calc(var(--lbr-size-unit, 8px) * 64);
  --lbr-size-x80: calc(var(--lbr-size-unit, 8px) * 80);
  --lbr-size-x86: calc(var(--lbr-size-unit, 8px) * 86);
  --lbr-size-x90: calc(var(--lbr-size-unit, 8px) * 90);
  --lbr-size-max: 10000px;
}

.svc-creator {
  --sjs-font-size-x1: calc(var(--ctr-font-unit, 8px) * 1);
  --sjs-font-size-x150: calc(var(--ctr-font-unit, 8px) * 1.5);
  --sjs-font-size-x2: calc(var(--ctr-font-unit, 8px) * 2);
  --sjs-font-size-x3: calc(var(--ctr-font-unit, 8px) * 3);
  --sjs-font-size-x4: calc(var(--ctr-font-unit, 8px) * 4);
  --sjs-font-size-x5: calc(var(--ctr-font-unit, 8px) * 5);
  --sjs-font-size-x6: calc(var(--ctr-font-unit, 8px) * 6);
  --sjs-font-size-x7: calc(var(--ctr-font-unit, 8px) * 7);
  --sjs-font-size-x8: calc(var(--ctr-font-unit, 8px) * 8);
  --sjs-line-height-x1: calc(var(--ctr-line-height-unit, 8px) * 1);
  --sjs-line-height-x150: calc(var(--ctr-line-height-unit, 8px) * 1.5);
  --sjs-line-height-x2: calc(var(--ctr-line-height-unit, 8px) * 2);
  --sjs-line-height-x3: calc(var(--ctr-line-height-unit, 8px) * 3);
  --sjs-line-height-x4: calc(var(--ctr-line-height-unit, 8px) * 4);
  --sjs-line-height-x5: calc(var(--ctr-line-height-unit, 8px) * 5);
  --sjs-line-height-x6: calc(var(--ctr-line-height-unit, 8px) * 6);
  --sjs-line-height-x7: calc(var(--ctr-line-height-unit, 8px) * 7);
  --sjs-line-height-x8: calc(var(--ctr-line-height-unit, 8px) * 8);
}

.svc-creator {
  --sjs-spacing-x2: calc(var(--ctr-spacing-unit, 8px) * 2);
  --sjs-spacing-x3: calc(var(--ctr-spacing-unit, 8px) * 3);
  --sjs-spacing-x1: calc(var(--ctr-spacing-unit, 8px) * 1);
  --sjs-spacing-x05: calc(var(--ctr-spacing-unit, 8px) * 0.5);
  --sjs-spacing-x025: calc(var(--ctr-spacing-unit, 8px) * 0.25);
  --sjs-spacing-x0125: calc(var(--ctr-spacing-unit, 8px) * 0.125);
  --sjs-spacing-x150: calc(var(--ctr-spacing-unit, 8px) * 1.5);
  --sjs-spacing-x250: calc(var(--ctr-spacing-unit, 8px) * 2.5);
  --sjs-spacing-x4: calc(var(--ctr-spacing-unit, 8px) * 4);
  --sjs-spacing-x350: calc(var(--ctr-spacing-unit, 8px) * 3.5);
  --sjs-spacing-x5: calc(var(--ctr-spacing-unit, 8px) * 5);
  --sjs-spacing-x6: calc(var(--ctr-spacing-unit, 8px) * 6);
  --sjs-spacing-x7: calc(var(--ctr-spacing-unit, 8px) * 7);
  --sjs-spacing-x8: calc(var(--ctr-spacing-unit, 8px) * 8);
  --sjs-spacing-x9: calc(var(--ctr-spacing-unit, 8px) * 9);
  --sjs-spacing-x10: calc(var(--ctr-spacing-unit, 8px) * 10);
  --sjs-spacing-x11: calc(var(--ctr-spacing-unit, 8px) * 11);
  --sjs-spacing-x12: calc(var(--ctr-spacing-unit, 8px) * 12);
  --sjs-spacing-x13: calc(var(--ctr-spacing-unit, 8px) * 13);
  --sjs-spacing-x14: calc(var(--ctr-spacing-unit, 8px) * 14);
  --sjs-spacing-x15: calc(var(--ctr-spacing-unit, 8px) * 15);
  --sjs-spacing-x16: calc(var(--ctr-spacing-unit, 8px) * 16);
  --sjs-spacing-x075: calc(var(--ctr-spacing-unit, 8px) * 0.75);
  --sjs-spacing-x125: calc(var(--ctr-spacing-unit, 8px) * 1.25);
  --sjs-spacing-x1050: calc(var(--ctr-spacing-unit, 8px) * 10.5);
  --sjs-spacing-x0375: calc(var(--ctr-spacing-unit, 8px) * 0.375);
  --sjs-spacing-x850: calc(var(--ctr-spacing-unit, 8px) * 8.5);
  --sjs-spacing-x025n: calc(var(--ctr-spacing-unit, 8px) * -0.25);
  --sjs-spacing-x3n: calc(var(--ctr-spacing-unit, 8px) * -3);
  --sjs-spacing-x4n: calc(var(--ctr-spacing-unit, 8px) * -4);
  --sjs-spacing-x05n: calc(var(--ctr-spacing-unit, 8px) * -0.5);
  --sjs-spacing-x1n: calc(var(--ctr-spacing-unit, 8px) * -1);
  --sjs-spacing-x150n: calc(var(--ctr-spacing-unit, 8px) * -1.5);
  --sjs-spacing-x2n: calc(var(--ctr-spacing-unit, 8px) * -2);
  --sjs-spacing-x5n: calc(var(--ctr-spacing-unit, 8px) * -5);
  --sjs-spacing-x6n: calc(var(--ctr-spacing-unit, 8px) * -6);
  --sjs-spacing-x7n: calc(var(--ctr-spacing-unit, 8px) * -7);
  --sjs-spacing-x8n: calc(var(--ctr-spacing-unit, 8px) * -8);
  --sjs-corner-radius-x0125: calc(var(--ctr-corner-radius-unit, 8px) * 0.125);
  --sjs-corner-radius-x025: calc(var(--ctr-corner-radius-unit, 8px) * 0.25);
  --sjs-corner-radius-x05: calc(var(--ctr-corner-radius-unit, 8px) * 0.5);
  --sjs-corner-radius-x075: calc(var(--ctr-corner-radius-unit, 8px) * 0.75);
  --sjs-corner-radius-x1: calc(var(--ctr-corner-radius-unit, 8px) * 1);
  --sjs-corner-radius-x125: calc(var(--ctr-corner-radius-unit, 8px) * 1.25);
  --sjs-corner-radius-x150: calc(var(--ctr-corner-radius-unit, 8px) * 1.5);
  --sjs-corner-radius-x2: calc(var(--ctr-corner-radius-unit, 8px) * 2);
  --sjs-corner-radius-x250: calc(var(--ctr-corner-radius-unit, 8px) * 2.5);
  --sjs-corner-radius-x3: calc(var(--ctr-corner-radius-unit, 8px) * 3);
  --sjs-corner-radius-x350: calc(var(--ctr-corner-radius-unit, 8px) * 3.5);
  --sjs-corner-radius-x4: calc(var(--ctr-corner-radius-unit, 8px) * 4);
  --sjs-corner-radius-x5: calc(var(--ctr-corner-radius-unit, 8px) * 5);
  --sjs-corner-radius-x6: calc(var(--ctr-corner-radius-unit, 8px) * 6);
  --sjs-corner-radius-x7: calc(var(--ctr-corner-radius-unit, 8px) * 7);
  --sjs-corner-radius-x8: calc(var(--ctr-corner-radius-unit, 8px) * 8);
  --sjs-corner-radius-round: calc(var(--ctr-corner-radius-unit, 8px) * 128);
  --sjs-corner-radius-x0375: calc(var(--ctr-corner-radius-unit, 8px) * 0.375);
  --sjs-stroke-x1: calc(var(--ctr-stroke-unit, 1px) * 1);
  --sjs-stroke-x2: calc(var(--ctr-stroke-unit, 1px) * 2);
  --sjs-stroke-x3: calc(var(--ctr-stroke-unit, 1px) * 3);
  --sjs-stroke-x4: calc(var(--ctr-stroke-unit, 1px) * 4);
  --sjs-stroke-x6: calc(var(--ctr-stroke-unit, 1px) * 6);
  --sjs-stroke-x8: calc(var(--ctr-stroke-unit, 1px) * 8);
  --sjs-size-x0125: calc(var(--ctr-size-unit, 8px) * 0.125);
  --sjs-size-x025: calc(var(--ctr-size-unit, 8px) * 0.25);
  --sjs-size-x05: calc(var(--ctr-size-unit, 8px) * 0.5);
  --sjs-size-x075: calc(var(--ctr-size-unit, 8px) * 0.75);
  --sjs-size-x1: calc(var(--ctr-size-unit, 8px) * 1);
  --sjs-size-x125: calc(var(--ctr-size-unit, 8px) * 1.25);
  --sjs-size-x150: calc(var(--ctr-size-unit, 8px) * 1.5);
  --sjs-size-x2: calc(var(--ctr-size-unit, 8px) * 2);
  --sjs-size-x250: calc(var(--ctr-size-unit, 8px) * 2.5);
  --sjs-size-x3: calc(var(--ctr-size-unit, 8px) * 3);
  --sjs-size-x350: calc(var(--ctr-size-unit, 8px) * 3.5);
  --sjs-size-x4: calc(var(--ctr-size-unit, 8px) * 4);
  --sjs-size-x5: calc(var(--ctr-size-unit, 8px) * 5);
  --sjs-size-x6: calc(var(--ctr-size-unit, 8px) * 6);
  --sjs-size-x7: calc(var(--ctr-size-unit, 8px) * 7);
  --sjs-size-x8: calc(var(--ctr-size-unit, 8px) * 8);
  --sjs-size-x9: calc(var(--ctr-size-unit, 8px) * 9);
  --sjs-size-x10: calc(var(--ctr-size-unit, 8px) * 10);
  --sjs-size-x11: calc(var(--ctr-size-unit, 8px) * 11);
  --sjs-size-x12: calc(var(--ctr-size-unit, 8px) * 12);
  --sjs-size-x13: calc(var(--ctr-size-unit, 8px) * 13);
  --sjs-size-x14: calc(var(--ctr-size-unit, 8px) * 14);
  --sjs-size-x15: calc(var(--ctr-size-unit, 8px) * 15);
  --sjs-size-x16: calc(var(--ctr-size-unit, 8px) * 16);
  --sjs-size-x550: calc(var(--ctr-size-unit, 8px) * 5.5);
  --sjs-size-max: 10000px;
  --sjs-size-x17: calc(var(--ctr-size-unit, 8px) * 17);
  --sjs-size-x18: calc(var(--ctr-size-unit, 8px) * 18);
  --sjs-size-x64: calc(var(--ctr-size-unit, 8px) * 64);
  --sjs-size-x90: calc(var(--ctr-size-unit, 8px) * 90);
  --sjs-size-x80: calc(var(--ctr-size-unit, 8px) * 80);
  --sjs-size-x40: calc(var(--ctr-size-unit, 8px) * 40);
  --sjs-size-x32: calc(var(--ctr-size-unit, 8px) * 32);
  --sjs-size-x20: calc(var(--ctr-size-unit, 8px) * 20);
  --sjs-size-x86: calc(var(--ctr-size-unit, 8px) * 86);
  --sjs-size-x60: calc(var(--ctr-size-unit, 8px) * 60);
  --sjs-size-x28: calc(var(--ctr-size-unit, 8px) * 28);
  --sjs-size-x50: calc(var(--ctr-size-unit, 8px) * 50);
}

.svc-btn {
  display: flex;
  align-items: center;
  height: calc(7 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  line-height: calc(7 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  vertical-align: baseline;
  text-align: center;
  background-color: var(--ctr-button-background-color, var(--sjs-general-backcolor, var(--background, #fff)));
  box-shadow: 0px 1px 2px 0px var(--ctr-shadow-small-color, rgba(0, 0, 0, 0.15));
  border-radius: var(--ctr-button-corner-radius, calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))));
  cursor: pointer;
  user-select: none;
  outline: solid calc(0.25 * (var(--sjs-base-unit, var(--base-unit, 8px)))) transparent;
  transition: background-color var(--sjs-creator-transition-duration, 150ms);
}
.svc-btn .svc-add-new-item-button__text {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  color: var(--ctr-button-text-color, var(--sjs-primary-backcolor, var(--primary, #19b394)));
}

.svc-btn:hover {
  background-color: var(--ctr-button-background-color-hovered, var(--sjs-questionpanel-hovercolor, var(--sjs-general-backcolor-dark, rgb(248, 248, 248))));
}

.svc-btn:focus {
  box-shadow: 0 0 0 2px var(--ctr-button-border-color-focused, var(--sjs-primary-backcolor, var(--primary, #19b394)));
}

.svc-action-button {
  --default-font-size: var(--sjs-font-size, calc(2 * var(--sjs-base-unit, var(--base-unit, 8px))));
  --ctr-default-line-height: calc(1.5 * var(--default-font-size));
  font-family: var(--ctr-font-family, var(--sjs-font-family, var(--font-family)));
  font-size: var(--ctr-font-default-size, var(--default-font-size, 16px));
  font-style: normal;
  font-weight: 400;
  line-height: var(--ctr-font-default-line-height, var(--ctr-default-line-height, 24px));
  font-weight: 600;
  display: inline-block;
  padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  box-sizing: border-box;
  border-radius: calc(12.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  color: var(--ctr-survey-action-button-text-color-positive, var(--sjs-primary-backcolor, var(--primary, #19b394)));
  border: calc(0.25 * (var(--sjs-base-unit, var(--base-unit, 8px)))) solid transparent;
}
.svc-action-button .svc-text {
  color: var(--ctr-survey-action-button-text-color-positive, var(--sjs-primary-backcolor, var(--primary, #19b394)));
}
.svc-action-button:focus, .svc-action-button:hover {
  background-color: var(--ctr-survey-action-button-background-color-hovered-positive, var(--sjs-primary-backcolor-light, var(--primary-light, rgba(25, 179, 148, 0.1))));
  outline: none;
}

.svc-action-button--selected {
  border-color: var(--ctr-survey-action-button-text-color-positive, var(--sjs-primary-backcolor, var(--primary, #19b394)));
}

.svc-action-button--disabled {
  opacity: var(--ctr-survey-action-button-opacity-disabled, 0.25);
  color: var(--ctr-survey-action-button-text-color-disabled, var(--sjs-general-forecolor, var(--foreground, #161616)));
}