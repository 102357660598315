.survaytemplate {
  position: absolute;
  height: 100%;
  width: calc(100% - 60px);
  .svc-creator {
    padding: 24px 0 !important;

    .svc-full-container {
      gap: 15px;
      background: transparent;
      margin-bottom: 30px;
      .svc-top-bar {
        margin-bottom: 22px;
      }
      // .svc-toolbox {
      //   border-radius: 4px;
      //   border: 1px solid var(--neutral-5, #d9d9d9);
      //   background: #fff;
      //   &__item {
      //     border-radius: 4px !important;
      //     width: 100%;
      //     cursor: pointer;
      //     &:hover {
      //       background: #ecf6ff;
      //       box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
      //     }
      //   }
      //   &__tool {
      //     width: 100%;
      //     cursor: pointer;
      //     display: block;
      //   }
      // }
      .svc-creator__content-wrapper {
        background: transparent;
        .sd-root-modern {
          background: transparent;
        }
      }
      .svc-side-bar__wrapper {
        border-radius: 4px;
        border: 1px solid var(--neutral-5, #d9d9d9);
        background: #fff;
        top: 0;
        .sv-components-row {
          height: 100%;
        }
      }
    }
    .svc-tabbed-menu {
      display: flex;
      align-items: center;
      gap: 30px;
      .svc-tabbed-menu-item {
        padding: 15px 0;
        &__text {
          font-weight: 400;
          font-size: 18px;
          line-height: 34px;
          display: flex;
          align-items: center;
          text-align: center;
          color: rgba(0, 0, 0, 0.85);
          cursor: pointer;
          position: relative;
          background: transparent;
          border: none;
          box-shadow: none;
        }
        &:hover {
          background-color: transparent !important;
        }
        &--selected {
          box-shadow: inset 0px -3px 0px var(--sjs-primary-backcolor, var(--primary, #096dd9));
          .svc-tabbed-menu-item__text {
            color: #096dd9;
            font-weight: 700;
          }
        }
      }
    }
  }
  .sd-container-modern {
    min-width: 0 !important;
  }
  .svc-json-editor-tab__content-area {
    min-height: 400px;
    border: 1px solid #ddd;
    border-radius: 10px;
  }
  .sd-title.sd-container-modern__title {
    box-shadow: 0px 2px 0px
      var(--sjs-primary-backcolor, var(--primary, #096dd9)) !important;
  }
  .svc-side-bar__container-header {
    box-shadow: inset 0px -2px 0px var(--sjs-primary-backcolor, var(--primary, #096dd9));
  }
  .svc-btn .svc-text {
    color: var(--sjs-primary-backcolor, var(--primary, #096dd9));
  }
  .svc-toolbox:not(.svc-toolbox--compact)
    .svc-toolbox__item:not(.sv-dots):focus
    .sv-svg-icon
    use,
  .svc-toolbox:not(.svc-toolbox--compact)
    .svc-toolbox__item:not(.sv-dots):hover
    .sv-svg-icon
    use {
    fill: var(--sjs-primary-backcolor, var(--primary, #096dd9));
  }
  .sd-question__content {
    overflow: visible !important;
  }
}
// .svc-tabbed-menu {
//   span:nth-last-child(2) {
//     display: none;
//   }
// }

.sdMainTitle + div {
  & > span {
    // background: #f3f3f3 !important;
    padding: 15px !important;
    border-radius: 12px !important;
  }
  & > button {
    right: 25px;
    top: 25px;
  }
}

@media only screen and (max-width: 1200px) {
  .survaytemplate {
    .svc-creator .svc-full-container {
      // display: flex;
      // flex-direction: column;
    }
    .svc-tabbed-menu-wrapper {
      overflow-x: auto;
      .svc-tabbed-menu {
        overflow: initial;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .survaytemplate {
    width: calc(100% - 20px);
    .svc-creator__content-wrapper {
      // height: auto;
      .svc-creator-tab {
        // display: block;
      }
      .svc-toolbox__category {
        // flex-direction: row;
      }
      .svc-tab-designer {
        height: 100vh;
      }
      .svc-toolbox__container {
        overflow-x: auto;
        .svc-toolbox__item {
          white-space: nowrap;
        }
      }
      .svc-toolbox__category-separator {
        display: none !important;
      }
    }
    .svc-side-bar__container {
      min-width: 0;
      width: 100% !important;
    }
    .svc-side-bar__container-wrapper {
      width: 60%;
    }
    .svc-row .sd-row.sd-row--multiple {
      flex-direction: column;
      > div {
        max-width: 100% !important;
      }
    }
  }
}

.svc-flex-row {
  .st-strings-header{
    height: 33px !important;
  }
}
