@import "../../variables.scss";

.svc-tab-logic-edit {
  width: 100%;
  height: 100%;
  background: $background-dim;
}

.svc-tab-logic-edit__content {
  height: calc(100% - calc(6 * #{$base-unit}));
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 16%;
  padding-right: 16%;
  //max-width: calcSize(75);
  //margin: auto;
  // background: $background;
}

.svc-tab-logic-edit__content .sd-row {
  flex-wrap: wrap;
}

.svc-logic_trigger-editor .sd-row {
  margin-top: 0;
}

.svc-tab-logic-edit__content-actions {
  .sv-action-bar {
    padding: 0;
    height: calc(6 * #{$base-unit});
    background: $background;
    border-top: 1px solid $border;
    width: 100%;
  }
}

.svc-logic-paneldynamic div.svc-logic-operator {
  box-sizing: content-box;
  max-width: calcSize(40);
}

.svc-logic-paneldynamic .svc-logic-operator {
  @include ctrDefaultBoldFont;
  -webkit-appearance: none;
  appearance: none;
  border-radius: var(--ctr-expression-item-corner-radius, calcSize(12.5));
  padding: var(--ctr-expression-item-padding-top, calcSize(1))
    var(--ctr-expression-item-padding-right, calcSize(2))
    var(--ctr-expression-item-padding-bottom, calcSize(1))
    var(--ctr-expression-item-padding-left, calcSize(2));

  border: none;
  outline: none;
  color: var(--ctr-expression-item-text-color-parameter-empty, $foreground-light);
  cursor: pointer;

  option {
    background: $background;
    color: $foreground;
  }
}

.svc-logic-operator:focus {
  outline: 1px dotted $primary;
}

.sl-question.svc-logic-question--answered .svc-logic-operator {
  color: var(--ctr-expression-item-text-color-parameter, $foreground);
}

.svc-logic-operator.svc-logic-operator {
  transition: background-color $creator-transition-duration, color $creator-transition-duration;

  &:hover,
  &:focus {
    color: var(--ctr-expression-item-text-color-parameter-hovered, $primary-foreground);
    outline: none;
    box-shadow: none;
  }
}

.svc-logic-operator.svc-logic-operator--question {
  background-color: var(--ctr-expression-item-background-color-parameter, $blue-light);
  color: var(--ctr-expression-item-text-color-parameter, $foreground);

  &:hover,
  &:focus {
    background-color: var(--ctr-expression-item-background-color-parameter-hovered, $blue);
    color: var(--ctr-expression-item-text-color-parameter-hovered, $background);
  }
}

.svc-logic-operator.svc-logic-operator--conjunction {
  background-color: var(--ctr-expression-item-background-color-conjunction, $yellow-light);
  color: var(--ctr-expression-item-text-color-conjunction, $foreground);

  &:hover,
  &:focus {
    background-color: var(--ctr-expression-item-background-color-conjunction-hovered, $yellow);
    color: var(--ctr-expression-item-text-color-conjunction-hovered, $primary-foreground);
  }
}

.svc-logic-operator.svc-logic-operator--operator {
  background-color: var(--ctr-expression-item-background-color-operator, $yellow-light);
  color: var(--ctr-expression-item-text-color-operator, $foreground);

  &:hover,
  &:focus {
    background-color: var(--ctr-expression-item-background-color-operator-hovered, $yellow);
    color: var(--ctr-expression-item-text-color-operator-hovered, $primary-foreground);
  }
}

.svc-logic-operator.svc-logic-operator--action {
  background-color: var(--ctr-expression-item-background-color-action, $red-light);
  color: var(--ctr-expression-item-text-color-action, $foreground);

  &:hover,
  &:focus {
    background-color: var(--ctr-expression-item-background-color-action-hovered, $red);
    color: var(--ctr-expression-item-text-color-action-hovered, $primary-foreground);
  }
}

.svc-logic-operator.svc-logic-operator--error {
  background-color: var(--ctr-expression-item-background-color-parameter-error, $background);
  color: var(--ctr-expression-item-text-color-parameter-error, $red);
  box-shadow: inset 0 0 0 2px var(--ctr-expression-item-text-color-parameter-error, $red);
}

.svc-logic-operator__error {
  display: none;
}

.svc-logic-paneldynamic {
  .sd-paneldynamic__separator {
    display: none;
  }
}

.svc-action-button.svc-logic-condition-remove.svc-icon-remove:focus {
  outline: none;
}


.svc-action-button--icon.svc-logic-condition-remove.svc-icon-remove {
  display: none;
  width: calcSize(5);
  height: calcSize(5);
  background: none;
  justify-content: center;
  align-items: center;
  padding: 0;

  .sv-svg-icon {
    width: calcSize(3);
    height: calcSize(3);
  }
  use {
    fill: var(--ctr-survey-action-button-icon-color-negative, $red);
  }
}

.svc-action-button--disabled.svc-action-button--icon.svc-logic-condition-remove.svc-icon-remove {
  use {
    fill: var(--ctr-survey-action-button-icon-color-disabled, $foreground-dim-light);
  }
}

.sl-panel-wrapper--in-row:hover .svc-logic-condition-remove.svc-icon-remove,
.sl-panel-wrapper--in-row:focus-within .svc-logic-condition-remove.svc-icon-remove {
  display: block;
}

.sl-panel-wrapper--in-row:hover .svc-logic-condition-remove.svc-icon-remove.svc-action-button--icon,
.sl-panel-wrapper--in-row:focus-within .svc-logic-condition-remove.svc-icon-remove.svc-action-button--icon {
  display: flex;
}

.svc-logic-condition-remove.svc-icon-remove:hover,
.svc-logic-condition-remove.svc-icon-remove:focus {
  background-color: var(--ctr-library-action-button-background-color-negative-hovered, $red-light);
}

.svc-logic-condition-remove-question {
  height: calcSize(5);
}

.svc-logic-paneldynamic__button.svc-logic-paneldynamic__remove-btn {
  display: none;
}

.svc-logic-paneldynamic__button.svc-logic-paneldynamic__remove-btn {
  display: none;
}

.svc-logic-operator--action.sl-paneldynamic__add-btn.sl-paneldynamic__add-btn,
.svc-logic-operator--operator.sl-paneldynamic__add-btn.sl-paneldynamic__add-btn {
  color: var(--ctr-expression-item-text-color-operator-empty, $foreground-light);
  margin-top: calcSize(2);

  &:hover,
  &:focus {
    color: var(--ctr-expression-item-background-color-operator-hovered, $primary-foreground);
  }
}

.svc-logic-tab__content {
  /* disable library transition animations */
  --sjs-transition-duration: 0ms;
}

.svc-logic-tab__content {
  &.svc-logic-tab--empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    flex-shrink: 0;
    width: 100%;
    gap: 0px;

    .svc-logic-tab__content-action {
      width: calcSize(33);
      margin-top: calcSize(1);
    }
  }

  .svc-logic-tab__content-action {
    margin-left: 25%;
    margin-right: 25%;
    margin-top: calcSize(5);
    margin-bottom: calcSize(8);
  }

  .svc-logic-tab__content-action--disabled {
    cursor: default;
    outline: solid calcSize(0.25) transparent;

    &:focus,
    &:hover {
      background-color: var(--ctr-button-background-color-hovered, $background);
      box-shadow: $shadow-small;
    }
  }

  .svc-logic-tab__content-action--disabled .svc-add-new-item-button__text {
    color: var(--ctr-button-text-color-disabled, $foreground);
    opacity: var(--ctr-button-text-opacity-disabled, 0.25);
  }

  .sd-paneldynamic__panel-wrapper--in-row {
    margin-top: 0;
    align-items: start;
  }
}

.svc-logic-tab__content-empty {
  .svc-add-new-item-button__text {
    color: var(--ctr-surface-placeholder-text-description-color, $foreground);
  }
}

.svc-logic-paneldynamic {
  .sd-row--multiple {
    padding: 0;
    background: none;
    box-shadow: none;
    border-radius: 0;
    overflow: visible;
  }

  .sd-question__content {
    margin-top: 0;
  }

  .sd-row__panel {
    width: calc(100% - 5 * #{$base-unit});
  }
}

.svc-logic-tab__content .svc-logic-paneldynamic .sd-row {
  margin-top: 0;
  flex-flow: row wrap;
}

.sl-table__cell.sd-table__cell-action--show-detail {
  use {
    fill: var(--ctr-collapse-button-icon-color, $foreground-light);
  }
}

.sl-table__cell--detail-panel {
  padding: 0;

  .sd-body {
    margin: 0;
    min-width: none;
    max-width: none;
  }

  .sd-page.sd-body__page {
    padding: 0;
  }

  .sl-panel__footer {
    margin: 0;
  }
}

.sl-dropdown {
  max-width: 45vw;
}

.sl-dropdown__value {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sl-list__item-body {
  padding-block: calcSize(1.5);
  border-radius: var(--ctr-list-item-corner-radius, 4px);
}

.svc-logic-placeholder {
  width: calcSize(78);
  min-width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin-top: calcSize(2);
  background: $background;
  box-shadow: $shadow-small;
  border-radius: calcSize(0.5);
  padding: calcSize(4) calcSize(5) calcSize(5);
}

.sv-popup--overlay {
  .svc-logic-placeholder {
    width: initial;
  }
}

.svc-logic-placeholder__text.svc-logic-placeholder__text {
  @include ctrDefaultFont;
  text-align: center;
  color: $foreground-light;
  display: block;
  padding: calcSize(4) calcSize(8);
}

.svc-logic-tab__leave-apply-button {
  background-color: var(--ctr-button-background-color-danger, $red);
}

.sl-list {
  padding-right: calcSize(0.5);
  padding-left: calcSize(0.5);

  .sv-list__filter {
    padding-top: 0;
  }

  .sv-list__filter-icon {
    top: calc(1.5 * var(--sjs-base-unit, var(--base-unit, 8px)));
  }
}

.sv-popup--dropdown-overlay .sv-list__filter {
  padding-top: calcSize(1.5);
}