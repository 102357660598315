.p-4 {
  padding: 10px;
}

.border-0 {
  border: none !important;
}
.planningTable {
  min-height: calc(100vh - 80px);
  padding: 20px 30px;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    .btn {
      font-size: 16px;
      line-height: 18px;
      color: #096dd9;
      background: #ecf6ff;
      border: none;
      padding: 7px 12px;
      cursor: pointer;
    }
  }
  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.85);
  }
  &__accordion {
    display: flex;
    align-items: center;
    padding: 20px 10px;
    column-gap: 13px;
    background-color: #ecf6ff;
    .profile {
      position: relative;
      width: 32px;
      height: 32px;
      img {
        width: 100%;
        border-radius: 100%;
      }
      .verified-icon {
        position: absolute;
        bottom: -3px;
        right: 0;
        width: 16px;
      }
    }
    h6 {
      margin: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 32px;
      color: rgba(0, 0, 0, 0.85);
      white-space: nowrap;
    }
  }
}
.customAccordion {
  &__row {
    background: #ffffff;
    // border: 1px solid #096dd9;
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 20px;
    display: grid;
    gap: 20px;

    &__inner {
      display: flex;
      align-items: center;
    }
  }
  &__rowCard {
    border: 1px solid #096dd9;
    border-radius: 4px;
  }
  &__show {
    height: 0;
    &.active {
      height: auto;
    }
  }
  &__Content {
    width: 100%;
    padding: 0px 10px;
    &.profilecol {
      min-width: 325px;
      padding: 0;
    }
    &.plannercol {
      min-width: 230px;
    }
    &.statuscol {
      min-width: 290px;
    }
  }
  &__show {
    .plansDetail {
      padding: 30px 25px 20px;
      &__innerbox {
      }
      p {
        margin-bottom: 0;
        margin-top: 25px;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: rgba(0, 0, 0, 0.85);
      }
      .Detailboxpart {
        display: flex;
        gap: 80px;
        align-items: center;
        &__box {
          display: flex;
          flex-direction: column;
          gap: 20px;
        }
      }
    }
  }
}
.statusBtnPart {
  display: flex;
  gap: 10px;
  .btn {
    max-width: 90px;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    border-radius: 3px;
    border: 0;
    //cursor: pointer;
    &.pendingBtn {
      background-color: #ffc701;
      border: 1px solid #ffc701;
      color: #000000;
    }
    &.completeBtn {
      background-color: #02c44f;
      border: 1px solid #02c44f;
      color: #fff;
    }
    &.publishBtn {
      background-color: #096dd9;
      border: 1px solid #096dd9;
      color: #fff;
      font-weight: 700;
      border-radius: 4px;
      padding: 5px;
      cursor: pointer;
    }
    &.unpublishBtn {
      background-color: transparent;
      border: 1px solid #096dd9;
      color: #096dd9;
      font-weight: 700;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
      border-radius: 4px;
      cursor: pointer;
    }
  }
}
.profilePart {
  display: flex;
  align-items: center;
  gap: 10px;
  img {
    border-radius: 100%;
    height: 30px;
    width: 30px;
    object-fit: cover;
    overflow: hidden;
  }
  h6 {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.85);
    white-space: nowrap;
  }
}

.planCard {
  display: flex;
  align-items: center;
  padding: 4px 10px;
  column-gap: 13px;
  background-color: #ecf6ff;
  width: 275px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  justify-content: space-between;
  & > div {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  h6 {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    margin: 0;
    margin-right: 10px;
  }
  .profile {
    position: relative;
    width: 32px;
    height: 32px;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 32px;
    img {
      width: 100%;
      border-radius: 100%;
    }
    .verified-icon {
      position: absolute;
      bottom: -3px;
      right: 0;
      width: 16px;
    }
  }
  .bookingStatus {
    span {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
    }
    h5 {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      margin: 0;
      text-align: center;
    }
  }
  &.bg-transparant {
    background-color: #fff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  }
}

.addPlanForm {
  &__field {
    padding: 35px;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    gap: 22px;
    .fromGroup-chips {
      margin-bottom: 0 !important;
    }
    .MuiFormLabel-root.MuiInputLabel-root {
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 18px !important;
      color: rgba(0, 0, 0, 0.85) !important;
    }
  }
}

.react-flow__handle {
  height: 12px !important;
  width: 12px !important;
  background: #fff !important;
  border: 3px solid #096dd9 !important;
  &-right {
    right: -6px !important;
  }
  &-left {
    left: -6px !important;
  }
}
.react-flow__edges {
  .react-flow__edge-path {
    stroke: #096dd9 !important;
    stroke-width: 3px !important;
  }
}
.react-flow__nodes {
  .react-flow__node:last-child {
    .react-flow__handle-left {
      display: none;
    }
  }
}
.planningTwo {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 95%;
  padding-bottom: 8px;
}
.backBtnArrow {
  display: flex;
  align-items: center;
}
.theme-light-btn {
  background-color: transparent !important;
  border: 1px solid #096dd9;
  color: #096dd9 !important;
  font-weight: 700;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 4px;
  &:hover {
    background-color: #096dd9 !important;
    color: #fff !important;
  }
}
.planning-map {
  height: calc(100vh - 290px);
  width: 100%;
  min-height: 550px;
}
.selected-filtersButtonsGroup {
  width: 95%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
  .border-button {
    background-color: transparent !important;
    border: 1px solid #096dd9;
    color: #096dd9 !important;
  }
}
.planning-details {
  display: flex;
  width: 100%;
  .mapUserList {
    max-height: 100vh;
    // height: calc(100vh - 290px);
  }
  .planningList {
    transition: width 0.5s;
    position: relative;
  }
  .listHeader {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 0px 10px;
  }
}
@media only screen and (max-width: 1024px) {
  .mapWrapper .splitter-layout {
    position: static;
    .layout-pane {
      width: 100% !important;
      & > div {
        // padding: 0 !important;
      }
    }
  }
}

.MuiTableHead-root {
  th {
    &:nth-child(2) {
      min-width: 120px;
    }
  }
}

.hidden-select {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
}

.DatePickerFilter {
  width: 168px !important;

  .MuiInputBase-root {
    height: 40px;
  }
}

.planning-filter-select {
  label {
    background-color: white;
    padding-left: 4px;
    padding-right: 4px;
  }
}
.calendar-header{
  width: 100%;
  display: flex;
  justify-content:space-between;
  align-items: center;
  padding: 10px 0px;
}
.zoom-controller {
  display: flex;
  align-items: center;
  .controller{
    display: flex;
    align-items: center;
    border: 1px solid #8080807a;
    height: 30px;
  }
  button {
    min-width: 25px;
  }
  .zoom-level{
    font-size: 15px;
    border-right: 1px solid #8080807a;
    border-left: 1px solid #8080807a;
    padding: 7px 5px;
  }
}
