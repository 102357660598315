.time-group-select {
  width: 100%;
}

.MuiCheckbox-root {
  background: transparent !important;
  padding: 0 !important;
  color: #d9d9d9 !important;
  margin-right: 6px;

  &.Mui-checked {
    color: #096dd9 !important;
  }
  &.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.26) !important;
  }
}

.p-30px {
  padding: 30px !important;
}

.p-40px {
  padding: 30px 40px 0px 40px !important;
}
.p-0 {
  padding: 0 !important;
}

.largeModel {
  padding: 20px;

  &__closeBtn {
    color: white;
    padding: 0;
    min-width: 0;
    height: 20px;
    width: 20px;
  }

  .MuiDialog-paper {
    max-width: 900px;
    width: 100%;
    margin: 0 auto !important;
    border-radius: 8px;
  }

  &__head {
    background: #096dd9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    position: sticky;
    top: 0;
    bottom: 10px;
    z-index: 11;
  }
  &__body {
    position: relative;
    z-index: 0;
  }
  &__title {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #ffffff;
    margin: 0;
  }

  &__footer {
    position: sticky;
    bottom: 0;
    background: #fff;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    box-shadow: inset 0px 1px 0px #f0f0f0;
    justify-content: flex-end;

    .border-button {
      padding: 4px 15px;
      height: 32px;
      background: #ffffff !important;
      border: 1px solid #096dd9;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
      border-radius: 4px;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      font-family: "Roboto", sans-serif;
      text-align: center;
      color: #096dd9 !important;
    }

    .button {
      padding: 4px 15px;
      height: 32px;
      background: #096dd9 !important;
      border: 1px solid #096dd9;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
      border-radius: 4px;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      font-family: "Roboto", sans-serif;
      text-align: center;
      color: #ffffff !important;
      &:hover {
        background: #fff !important;
        color: #096dd9 !important;
      }
    }
  }

  &__stepperHead {
    display: flex;
    justify-content: center;
    position: sticky;
    //top: 34px;
    background: #fff;
    z-index: 12;
    //padding: 0px 0;
    //margin-bottom: 10px;

    .MuiStepper-horizontal {
      justify-content: center;
    }

    .MuiStep-root {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 125px;
    }

    .MuiStepLabel-iconContainer {
      svg {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        font-family: "Roboto", sans-serif;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: #fff;
        border: 2px solid rgba(0, 0, 0, 0.25);

        text {
          fill: rgba(0, 0, 0, 0.25) !important;
        }
      }

      &.Mui-active {
        svg {
          border: none;
          color: #096dd9;

          text {
            fill: #fff !important;
          }
        }
      }

      &.Mui-completed {
        svg {
          border: none;
          color: #096dd9;
        }
      }
    }

    .MuiStepLabel-label {
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      text-align: center;
      color: rgba(0, 0, 0, 0.25);

      &.Mui-active {
        color: #096dd9;
      }
    }

    .MuiStepConnector-root {
      top: 22px;
      left: calc(-50% + 22px);
      right: calc(50% + 22px);
      z-index: 0;

      .MuiStepConnector-line {
        border-color: #d9d9d9;
        border-top-width: 3px;
      }
    }

    .MuiStepLabel-root {
      position: relative;
      z-index: 1;
    }
  }

  &--edit {
    .stepperForm__title {
      margin: 40px auto 24px;
    }

    .InsuranceHeader {
      margin-top: 40px;
    }
  }
}

.smallModel {
  &__head {
    background: #096dd9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 16px;
    button {
      color: #fff;
    }
  }

  &__title {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    color: #fff;
  }
  &__closeBtn {
    padding: 0;
    color: #fff;
    min-width: auto;
  }

  &__footer {
    padding: 0px 20px 20px 20px;
    margin-top: 40px;
    position: absolute;
    bottom: 0;
    right: 0;

    .border-button {
      border-radius: 4px;
    }

    .button {
      background: #096dd9 !important;
      border: 1px solid #096dd9;
      border-radius: 4px;
      font-weight: 700;
      padding: 10px 15px;
      &:hover {
        background: #fff !important;
        color: #096dd9 !important;
      }
    }
  }
  .fromLabel {
    font-weight: 500;
  }

  .fromGroup {
    padding: 20px;
  }
  .MuiDialog-paper {
    max-width: 500px;
    width: 100%;
  }

  .MuiDialogContent-root {
    padding: 30px 25px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.85);
    max-width: 600px;
    text-align: center;
  }

  .MuiDialogActions-root {
    position: sticky;
    bottom: 0;
    background: #fff;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    box-shadow: inset 0px 1px 0px #f0f0f0;

    .border-button {
      padding: 4px 15px;
      height: 32px;
      background: #ffffff !important;
      border: 1px solid #096dd9;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
      border-radius: 4px;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      font-family: "Roboto", sans-serif;
      text-align: center;
      color: #096dd9 !important;
    }

    .button {
      padding: 4px 15px;
      height: 32px;
      background: #096dd9 !important;
      border: 1px solid #096dd9;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
      border-radius: 4px;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      font-family: "Roboto", sans-serif;
      text-align: center;
      color: #ffffff !important;
    }
  }
}

.select-filters-buttons {
  // display: flex;
  // // flex-wrap: wrap;
  // align-items: center;
  // gap: 15px;

  .selected-filtersButtons {
    display: flex;
    align-items: end;
    flex-wrap: wrap;
    justify-content: flex-end;
    flex-direction: column;

    // button {
    //   font-family: "Roboto", sans-serif;
    //   background: #ecf6ff !important;
    //   border: 1px solid #096dd9 !important;
    //   border-radius: 2px;
    //   padding: 7px 15px;
    //   font-weight: 400;
    //   font-size: 16px;
    //   line-height: 18px;
    //   color: #096dd9 !important;
    //   margin: 5px 0;
    //   margin-right: 8px;
    //   min-height: 32px;
    // }
  }

  .filterGroup-btn {
    flex-grow: 0;
    flex-shrink: 0;
    // flex-basis: 160px;
    flex-basis: auto;
  }
}

.Mui-error {
  margin-left: 0;
  margin-right: 0;
}

.addAddressBtn {
  position: absolute;
  right: 0;
  top: 0;
  background: transparent !important;
  color: #096dd9 !important;
  padding: 0;
}

.verifiedAddress {
  height: 25px;
  width: 25px;
  margin-left: 6px;
  margin-bottom: -10px;
}

.fromGroup {
  margin-bottom: 4px;

  .MuiInput-root {
    width: 100%;

    &::after,
    &::before {
      display: none;
    }
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .MuiInputBase-root {
    width: 100%;

    &.Mui-focused {
      input {
        background: #ecf6ff;
        border: 1px solid #096dd9;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
        border-radius: 2px;
      }
    }
  }

  .MuiSelect-select {
    padding: 8px 12px;
    height: 40px;
    background: #ffffff;
    border: none;
    border-radius: 2px;
    width: 100%;
    box-sizing: border-box;
  }

  input {
    padding: 8px 12px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    width: 100%;
    box-sizing: border-box;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #d9d9d9;
  }

  &-chips {
    display: flex;
    flex-wrap: wrap;

    .MuiPaper-root {
      height: 40px;
      background: #fafafa;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 15px;
      box-shadow: none;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      font-family: "Roboto", sans-serif;
      color: rgba(0, 0, 0, 0.85);
      cursor: pointer;
      margin: 4px 0;

      // &:not(:last-child) {
      //   margin-right: 15px;
      // }
    }
  }

  .fromGroup-chip-active {
    // background: #ecf6ff;
    color: #096dd9;
    border: 1px solid #096dd9;
  }

  .fromGroup-chip-inactive {
    border: 1px solid #000000;
  }
}

.calenderDate {
  .MuiFormControl-root {
    width: 100%;
  }

  .MuiInputBase-input {
    padding: 8px 7px;
    height: 40px;
    background: #ffffff;
    border: none;
    border-radius: 2px;
    width: 100%;
    box-sizing: border-box;
  }
}

.authListBox {
  background: #ffffff;
  padding: 15px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  height: 100%;

  &__item {
    margin-bottom: 10px;
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    background: transparent;
    padding: 10px;
    border-radius: 5px;
    &.active {
      background: #d9d9d9;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    line-height: 24px;
    margin: 0 !important;
    color: rgba(0, 0, 0, 0.85);
    //line-break: anywhere;
    flex-wrap: wrap;
  }

  &__removeBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
      height: 22px;
      width: 22px;
      color: #096dd9;
    }
  }

  &__link {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.85);
    display: block;
    padding: 4px 10px;
    cursor: pointer;

    &.activeLink {
      color: #096dd9;
      text-decoration: underline;
      font-weight: 700;
    }
  }
}

.addBtn {
  background: transparent !important;
  color: #096dd9 !important;
  padding: 0;
}

.addBtnDisable {
  background: transparent !important;
  color: #a9a9a9 !important;
  padding: 0;
}

.InsuranceHeader {
  margin-bottom: 20px;

  .stepperForm__title {
    padding: 0 !important;
    margin: 0 !important;
  }

  &__btn {
    text-align: right;

    button {
      background: transparent;
      border: none;
      color: #096dd9;
    }
  }
}

.Insurance-form {
  .checkBox-group {
    justify-content: center;
  }

  .MuiGrid-grid-sm-4 label {
    text-align: center;
  }
}

.fromLabel {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.85);
  margin: 0 0 8px;
}

.time-group {
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  display: grid;
  grid-template-columns: 120px 20px 120px 20px 120px 38px 38px;

  &__space {
    font-family: "Roboto", sans-serif;
    margin: 0 8px;
    font-weight: 500;
    font-size: 18px;
    line-height: 36px;
    color: rgba(0, 0, 0, 0.85);

    &--time {
      flex: 50px 0 0;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .weeklyTime {
    text-align: left;
    margin: 0 !important;
    font-size: 14px;

    span {
      margin-left: 0;
    }
  }
}

textarea {
  width: 100% !important;
  box-sizing: border-box;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(217, 217, 217);
  border-radius: 2px;
  height: 63px;
  padding: 8px 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #000;
  font-family: "Roboto", sans-serif;
}

.checkBox-group {
  display: flex;
  align-items: center;

  &.vertical-group {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .MuiFormControlLabel-root {
    margin: 0 !important;
  }

  .MuiCheckbox-root {
    background: transparent !important;
    padding: 0 !important;
    color: #d9d9d9 !important;
    margin-right: 6px;

    &.Mui-checked {
      color: #096dd9 !important;
    }
  }

  .MuiFormControlLabel-label {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    font-family: "Roboto", sans-serif;
    color: rgba(0, 0, 0, 0.85);
  }
}

.MuiPopover-root.MuiMenu-root {
  .MuiMenu-paper {
    ul {
      padding: 0;

      li {
        background-color: #fff !important;
        color: #000000d9 !important;
        padding: 11px 12px;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        font-family: "Roboto", sans-serif;

        &:hover {
          background: #ecf6ff !important;
          color: #096dd9 !important;
        }
      }
    }
  }
}

.removeTimeBtn {
  width: 22px;
  cursor: pointer;
  display: flex;
  height: 100%;
  padding-top: 0px;

  path {
    color: #096dd9;
  }
}

.notFound-Block {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #666666;
}

.matchAppointment-Block {
  min-height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.85);
  background: #ecf6ff;
  border-radius: 4px;
}

th,
td {
  .matchAppointment-Block {
    width: 100%;
  }
}

.formSubmitBlock {
  .stepperForm__footer {
    position: inherit;
    justify-content: end;
  }
}

.formSubmitStatus {
  padding: 64px;
  text-align: center;

  &__icon {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background-color: #096dd9;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 45px;

    svg {
      height: 70px;
      width: 70px;
      fill: #fff;
    }
  }

  &__text {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 30px;
    line-height: 40px;
    color: #096dd9;
    text-align: center;
    margin: 0;
  }
}

.addressSuggestionList {
  background: #fff;
  border: 1px solid #eee;
  padding: 3px;
  margin: 0;
  list-style-type: none;
  border-radius: 6px;

  li {
    padding: 8px;
    font-size: 14px;
    cursor: pointer;

    &:not(:last-child) {
      border-bottom: 1px solid #eee;
    }

    &:hover {
      background-color: #ecf6ff;
    }
  }
}

.matchCards {
  display: flex;
  gap: 10px;

  & > div {
    background: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.85);
    padding: 7px 12px;
  }
}

.selectAuthRadio {
  margin-top: 16px;

  &__title {
    display: grid;
    grid-template-columns: 6fr 2fr 2fr 2fr 2fr;
    gap: 15px;
    margin-bottom: 8px;

    div {
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
      font-family: "Roboto", sans-serif;
      color: #666666;
      text-align: left !important;
    }
  }

  &__item {
    display: grid;
    grid-template-columns: 6fr 2fr 2fr 2fr 2fr;
    gap: 15px;
    margin-top: 5px;
    margin-bottom: 20px;
    div {
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.85);
    }

    .MuiFormControlLabel-root {
      margin-left: 0 !important;
      text-align: left;
      align-items: flex-start;
    }

    .remainingHours-data {
      p {
        display: none;
      }
    }

    .text-center {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 30px;
    }

    &:last-child {
      .remainingHours-data {
        p {
          display: block;
        }
      }
    }
  }
}

.remainingHours-data {
  display: flex;
  align-items: center;
  gap: 22px;
  justify-content: left;

  div {
    text-align: center;

    span {
      background: #ecf6ff;
      border-radius: 4px;
      min-height: 24px;
      // min-width: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    p {
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
      color: #666666;
      margin: 5px;
    }
  }
}

.MuiButtonBase-root.MuiRadio-root {
  background-color: transparent !important;
  padding: 5px !important;
  color: #d9d9d9 !important;

  svg {
    height: 20px;
    width: 20px;
  }

  &.Mui-checked {
    color: rgb(9, 109, 217) !important;
  }
}

.datePicker {
  .MuiFormControl-root.MuiTextField-root {
    width: 100%;

    .MuiInputBase-root.MuiOutlinedInput-root {
      height: 40px;
    }

    input {
      padding: 8px 12px;
      height: 40px;
      background: #ffffff;
      border: none;
      border-radius: 2px;
      width: 100%;
    }
  }

  .rdrCalendarWrapper {
    top: 65px;
    left: 0;
  }
}

.scheduleFilter {
  .MuiDialog-paper {
    overflow: visible;
  }
}

.stateCard {
  background: #fff;
  border-radius: 4px;
  padding: 15px;
  text-align: center;
  height: 100%;
  cursor: pointer;

  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
    margin: 0 0 16px;
  }

  &__img {
    max-width: 112px;
    display: block;
    margin: 0 auto 12px;

    img {
      width: 100%;
    }
  }

  &__text {
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.85);
    margin: 0;
  }

  &--pink {
    background: #d6665c1a;
  }

  &--green {
    background: rgba(58, 120, 79, 0.1);
  }
}

.StageCard {
  background: #ecf6ff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 10px !important;
  margin: 5px;
  width: 100%;
  min-width: 200px;
  max-width: 2800px;
  // flex-grow: 0;
  // flex-shrink: 0;
  // flex-basis: calc(25% - 24px);
  height: fit-content;
  // overflow-x: auto !important;

  &__title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
    margin: 0 0 8px;
    width: 100%;
    overflow: hidden;
    // text-overflow: ellipsis;
    font-weight: 600;
    white-space: normal; /* Allows text wrapping */
  }

  &__status {
    text-align: right;
    margin-bottom: 8px;

    div {
      display: inline-block;
      background: #ecf6ff;
      border: 1px solid #096dd9;
      border-radius: 2px;
      padding: 1px 8px;
      font-weight: 300;
      font-size: 12px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.85);
    }
  }

  &__dropBox {
    & > div {
      min-height: 12px;
    }
  }
}

.StageInnerCard {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgb(0 0 0 / 10%);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  padding: 16px !important;
  margin: 5px 0 0;

  &__user {
    // display: flex;
    // align-items: center;
    // gap: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  &__userInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 9;
  }

  &__userImage {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    overflow: hidden;
    width: 30px;
    margin-right: 10px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &__userName {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.85);
    margin: 0 0 2px;

    &.activeLink {
      color: #096dd9;
      text-decoration: underline;
      font-weight: 700;
      width: 80px;
      word-wrap: break-word;
    }
  }

  &__userInfo {
    flex: 9;
    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #666666;
      margin: 0;
    }
  }

  &__time {
    color: #666666; // rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin: 7px 0 5px;
  }

  &__info {
    button {
      padding: 0;
      border: none;
      background: transparent;
      color: #096dd9;

      svg {
        path {
          fill: #096dd9;
        }
      }
    }
  }

  &.selected {
    background: rgb(238, 204, 141);
  }
}

.popoverLinkList {
  padding: 15px 16px;
  margin: 0;
  min-width: 195px;
  list-style-type: none;

  li {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0px;
    }

    &:hover {
      color: #096dd9;
    }
  }
}

.carousel__slide-focus-ring {
  display: none;
}

.MuiPaper-root.MuiPaper-elevation {
  box-shadow: 0px 1px 4px rgb(0 0 0 / 10%);
}

@media only screen and (max-width: 1240px) {
  .StageCard {
    width: 33%;
    flex-basis: calc(33% - 24px);
  }
}
@media only screen and (max-width: 1100px) {
  .planningTwo {
    margin: 10px 0px 20px;
    width: 100% !important;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }
}
@media only screen and (max-width: 1024px) {
  .StageCard {
    width: 50%;
    flex-basis: calc(50% - 24px);
  }
  .splitter-layout {
    flex-direction: column !important;
  }

  .select-filters-buttons {
    .selected-filtersButtons {
      align-items: flex-start;
    }
  }
  // .mapWrapper {
  //   .innerTabs {
  //     margin-top: 30px !important;
  //   }
  // }
}

@media only screen and (max-width: 767px) {
  .largeModel__stepperHead {
    .MuiStep-root {
      flex-basis: 95px;
      padding: 0;
    }

    .MuiStepLabel-iconContainer svg {
      width: 32px;
      height: 32px;
    }

    .MuiStepLabel-label {
      font-size: 13px;
      margin-top: 10px;
      font-weight: 500;
    }

    .MuiStepConnector-root {
      top: 16px;
      left: calc(-50% + 15px);
      right: calc(50% + 15px);
    }
  }

  .checkBox-group .MuiFormControlLabel-label {
    font-size: 16px;
    line-height: 20px;
  }

  .stepperForm__title {
    font-size: 22px;
  }

  .formSubmitStatus {
    padding: 45px 25px;

    &__icon {
      height: 75px;
      width: 75px;
      margin: 0 auto 28px;

      svg {
        height: 45px;
        width: 45px;
      }
    }

    &__text {
      font-size: 24px;
      line-height: 32px;
    }
  }

  .scheduleFilter {
    .MuiDialog-paper {
      overflow: auto;
    }
  }

  .StageCard {
    width: 100%;
    flex-basis: calc(90% - 24px);
  }
}

@media only screen and (max-width: 600px) {
  .checkBox-group {
    margin-bottom: 5px;
  }

  .fromGroup input {
    padding: 8px 10px;
    font-size: 14px;
    height: 38px;
  }

  .fromLabel {
    font-size: 14px;
    overflow: inherit;
    white-space: break-spaces;
  }

  .fromGroup-chips .MuiPaper-root {
    height: 38px;
    padding: 8px 10px;
    margin-bottom: 3px;
    font-size: 14px;
  }

  .fromGroup-chips .MuiPaper-root:not(:last-child) {
    margin-right: 10px;
  }

  .largeModel__title {
    font-size: 20px;
    line-height: 28px;
  }

  .largeModel__stepperHead {
    overflow: scroll;
    justify-content: flex-start;
    // margin: 0 -26px 0;
    padding: 40px 20px;
    top: 60px;

    .MuiStepper-horizontal {
      justify-content: flex-start;
      padding: 0;
    }
  }
}

.smallModel--edit {
  .MuiDialog-paper {
    height: 550px;
  }
}
