.backBtn {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: #096dd9 !important;
  background: transparent !important;
  border: none;
  padding: 0;
  text-transform: capitalize;
  margin: 0 30px 8px 0;
}

.detailsPageHeader {
  margin-bottom: 25px;

  &__btn {
    text-align: right;

    button {
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      font-size: 18px;
      line-height: 18px;
      color: #096dd9 !important;
      background: transparent !important;
      border: none;
      padding: 0;
      margin: 0 0;
    }
  }
}

.DetailsPageTitle {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.85);
  margin: 0px !important;
}

.uploadProfile {
  background: #ffffff;
  // box-shadow: 0px 2px 8px rgb(0 0 0 / 15%);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 99%;

  &__btn {
    // margin-top: 10px;
    height: 64px;
    padding: 22px 10px;
    background: #f6fbff !important;
    border-radius: 0px 0px 4px 4px;
    width: 100%;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #096dd9 !important;
    box-shadow: none;

    &:hover {
      box-shadow: none !important;
    }
  }

  &__img {
    height: calc(100% - 64px);
    // height: calc(100% - 164px);
    width: 100%;
    padding: 5px;

    img {
      height: 80%;
      width: 80%;
      object-fit: contain;
    }
  }
}

.detailsCard {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  height: 100%;

  &__header {
    background: #096dd9;
    border-radius: 4px 4px 0px 0px;
    padding: 15px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #ffffff;
    margin: 0;
  }

  &__headBtn {
    color: white;
    padding: 0;
    border-bottom: 1px solid #fff;
    border-radius: 0;
    background: transparent !important;
    padding-bottom: 3px;

    svg {
      height: 16px;
      width: 16px;
    }
  }

  &__body {
    padding: 20px;
    height: calc(100% - 50px);

    & > div:last-child {
      margin-bottom: 0;

      .detailsCard-part {
        margin-bottom: 0;
      }
    }
  }

  &-lastBtn {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #096dd9 !important;
    background: transparent !important;
    border: none;
    padding: 0;
    margin: 15px 0 0;

    svg {
      margin-left: 0;
    }
  }

  &__stepper {
    padding: 20px 0 40px;
  }
}

.parentSlide {
  display: flex;
  justify-content: center;

  .active {
    background: #1d81ec;
  }

  span {
    background: #9bc4f0;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin: 5px;
    cursor: pointer;
  }
}

.userBasicInfoBox {
  display: flex;
  flex-wrap: wrap;

  &__img {
    height: 180px;
    width: 180px;
    margin-right: 22px;
    //border: 1px solid #eee;
    //border-radius: 4px;
    overflow: hidden;

    img {
      width: 80%;
      height: 80%;
      object-fit: cover;
      //object-fit: contain; //not use this!!!
    }
  }

  &__details {
    width: calc(100% - 202px);

    .detailsCard-part {
      margin-bottom: 20px !important;
    }
  }

  &__title {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
    margin: 0 0 20px;
  }

  &--small {
    .userBasicInfoBox__img {
      height: 300px;
      width: 210px;
    }

    .userBasicInfoBox__details {
      width: calc(100% - 232px);
    }
  }
}

.remainingHours {
  background: #ecf6ff;
  border-radius: 4px;
  padding: 12px 18px;
  text-align: center;
  height: auto !important;

  &__title {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 36px;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.85);
  }

  &__text {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.85);
    margin-top: 8px;
  }
}

.detailsCard-part {
  margin-bottom: 24px;

  &__title {
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    font-family: "Roboto", sans-serif;
    color: #666666;
    margin-bottom: 8px;
  }

  &__text {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.85);
    margin-top: 4px;
    // line-break: anywhere;
    // display: flex;
    align-items: center;
    gap: 8px;

    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #666666;
    }

    .redText {
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: #cf3e4a;
    }
  }

  .MuiInputBase-root {
    width: 100%;
  }

  &__chips {
    display: flex;
    flex-wrap: wrap;
  }

  &__chip {
    background: #fafafa;
    padding: 3px 15px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    margin: 5px 15px 5px 0;
    box-shadow: none;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;

    &.active-chip {
      background: #ecf6ff;
      color: #096dd9;
      border: 1px solid #096dd9;
    }
  }

  &__fileItem {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.85);
    }

    button {
      padding: 0;
      margin: 0;
      background: transparent !important;
      color: #096dd9 !important;

      svg {
        height: 16px;
        width: 16px;
      }
    }
  }
}

.addressCard {
  background: #ecf6ff;
  padding: 15px 15px 0;
  margin-bottom: 20px;
  margin-left: -15px;
  width: calc(100% + 30px);

  .detailsCard-part {
    margin-bottom: 15px;
  }

  .verifiedAddress {
    height: 25px;
    width: 25px;
    margin-left: 6px;
    margin-bottom: -10px;
  }
}

.BasicInfoBox-client {
  .detailsCard__body {
    padding: 0;
  }

  .userBasicInfoBox__img {
    height: 265px;
    width: 240px;
    margin-right: 0;
  }

  .userBasicInfoBox__details {
    width: calc(100% - 240px);
    padding: 24px;
  }

  .uploadProfile__btn {
    height: 45px;
    padding: 14px 10px;
  }

  .uploadProfile__img {
    height: calc(100% - 25px);
    position: relative;
    top: 8px;
    left: 25px;
  }
}

.contactProviderCard {
  background: #ecf6ff;
  border-radius: 4px;
  padding: 10px;
  width: 90%;
}

.smallCardIcons {
  background: #ecf6ff;
  border-radius: 5px;
  padding: 25px;
  text-align: center;
  margin-bottom: 25px;

  svg {
    width: 140px;
    height: 140px;
  }
}

.smallCardText {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
}

@media only screen and (max-width: 1220px) {
  .detailsCard-part__chip {
    padding: 3px 12px;
    margin: 3px 10px 3px 0;
    font-size: 14px;
    line-height: 18px;
  }
}

@media only screen and (max-width: 1024px) {
  .detailsCard {
    &__header {
      padding: 15px 20px;
    }

    &__body {
      padding: 20px 20px;
    }

    &__title {
      font-size: 18px;
    }
  }

  .uploadProfile__btn {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .userBasicInfoBox {
    display: block;

    &__img {
      margin-right: 0;
      margin-bottom: 25px;
      width: 100% !important;
    }

    &__details {
      width: 100% !important;
      > div {
        .MuiGrid-item {
          max-width: 100%;
        }
        flex-direction: column;
      }
    }

    &--small {
      .userBasicInfoBox__img {
        height: 250px;
      }

      .userBasicInfoBox__details {
        width: 100%;
      }
    }
  }

  .contactProviderCard {
    width: 100%;
    margin: 15px 0;
  }

  .smallCardIcons svg {
    width: 75px;
    height: 75px;
  }

  .DetailsPageTitle {
    font-size: 20px;
    line-height: 28px;
    margin: 5px 0;
  }
}

.providerDialog {
  .providerDilogBox {
    padding: 30px 30px 0;
    & > div > div {
      max-width: 100% !important;
      max-height: 80px;
      overflow-y: auto;
      width: 100%;
    }
  }
}
.padidng-form {
  padding: 15px !important;
  min-height: 110px;
}
.providerDilogBox-inner {
  // padding: 15px;
  > div {
    width: 100%;
    max-height: 94px;
    overflow: auto;
  }
}
#customized-hook-demo-listbox {
  left: 15px;
  right: 15px;
  width: auto;
  max-height: 100px;
}

.innerModelSearch {
  position: relative;
  display: flex;
  align-items: center;
  & > svg {
    position: absolute;
    left: 15 px;
    z-index: 1;
  }
  .MuiFormControl-root {
    width: 100%;
  }
  .MuiInputBase-root {
    &::after,
    &::before {
      display: none;
    }
  }
  input {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15) !important;
    border-radius: 4px !important;
    border: none !important;
    padding-left: 38px !important;
    padding: 10px;
  }
}

.modelSearch-data {
  background: #fff; //#ecf6ff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  max-height: 120px;
  overflow: auto;
  & > div {
    padding: 10px 12px 10px 38px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    span {
      font-weight: 400;
      font-size: 18px;
      line-height: 18px;
      display: block;
      color: #096dd9;
      cursor: pointer;
    }
    &.disabled {
      opacity: 30%;
      pointer-events: none;
      cursor: default;
    }
  }
}

.description-rebook {
  margin-top: 15px;
  padding: 7px;
  p {
    margin-top: 15px;
  }

  span {
    color: dimgray;
    font-size: 16px;
    font-weight: lighter;
  }
}
.fromLabel {
  color: #000;
  font-size: 16px;
  font-weight: bold;
}
.rebook-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.client-addAuth-uploadProfile{
  height: 100%;
}
@media only screen and (max-width: 600px) {
  .uploadProfile__img {
    height: 200px;
    img {
      object-fit: contain;
    }
  }

  .selectedIn-fileBox {
    height: auto;
  }
  + .userBasicInfoBox {
    &__img {
      img {
        object-fit: contain;
      }
    }
    &__details {
      .detailsCard-part {
        margin-bottom: 10px !important;
      }
    }
  }
}

.certificatesTable {
  table {
    min-width: 900px;
  }
  td:first-child, th:first-child, td:nth-last-child(2), th:nth-last-child(2), td:nth-child(2), th:nth-child(2){
    width: 270px;
    @media only screen and (max-width: 1100px) {
      width: 220px;
    }
  }
  td:nth-child(3), th:nth-child(3) {
    min-width: 155px;
  }
  td:last-child, th:last-child {
    width: 90px;
  }
}
.paneledTable {
  table {
    min-width: 900px;
  }
  td:first-child, th:first-child, td:nth-last-child(2), th:nth-last-child(2), td:nth-child(2), th:nth-child(2){
    width: 270px;
    @media only screen and (max-width: 1100px) {
      width: 220px;
    }
  }
  td:nth-child(3), th:nth-child(3) {
    min-width: 155px;
  }
  td:last-child, th:last-child {
    width: 90px;
  }
}