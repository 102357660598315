.pageTemplate {
  padding: 30px 30px;
  width: 100%;
  height: calc(100vh - 80px);
  overflow: auto;
}

.stepperForm {
  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #096dd9;
    margin-bottom: 24px;
    margin-top: 0px;
  }

  &__footer {
    position: sticky;
    bottom: 0;
    background: #fff;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    box-shadow: inset 0px 1px 0px #f0f0f0;

    .border-button {
      padding: 4px 15px;
      height: 32px;
      background: #ffffff !important;
      border: 1px solid #096dd9;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
      border-radius: 4px;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      font-family: "Roboto", sans-serif;
      text-align: center;
      color: #096dd9 !important;
    }

    .button {
      padding: 4px 15px;
      height: 32px;
      background: #096dd9 !important;
      border: 1px solid #096dd9;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
      border-radius: 4px;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      font-family: "Roboto", sans-serif;
      text-align: center;
      color: #ffffff !important;
      &:hover {
        background: #fff !important;
        color: #096dd9 !important;
      }
    }
  }

  &__inner {
    margin: 0 56px;
    padding: 15px 10px;
    // padding-top: 30px;
  }

}

