.pageHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;
  // margin-bottom: 30px;
  &__title {
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
    margin: 0;
  }
  button {
    padding: 4px 24px;
  }
}
.p-10 {
  padding: 0px 10px !important;
}
.massageTemplate label {
  color: rgba(0, 0, 0, 0.85) !important;
  font-family: "Roboto", sans-serif;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  margin-bottom: 10px !important;
}
.template-heading {
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
}
.save-temp {
  display: flex;
  align-items: center;
  justify-content: end;
}
.massageBox {
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  .ql-toolbar {
    background: rgba(0, 0, 0, 0.02);
    border: none !important;
  }
  .ql-container {
    height: 450px;
    border: none !important;
  }
  .ql-toolbar.ql-snow {
    .ql-formats {
      margin-right: 5px;
    }
    button {
      padding: 3px 5px;
      width: 25px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .pageTemplate {
    padding: 10px 10px !important;
  }
  .massageBox {
    .ql-container {
      height: 300px;
      border: none !important;
    }
  }
}

@media only screen and (max-width: 898px) {
  .save-temp {
    margin-bottom: 10px !important;
  }
}
