body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

button {
  font-weight: 500 !important;
}

.e-content {
  height: fit-content !important;
}

.MuiGrid-root>.MuiGrid-item {
  padding: 4px
}

.MuiSwitch-root .MuiSwitch-thumb {
  width: 16px;
  height: 16px;
  background-color: white;
}

.MuiSwitch-root .MuiSwitch-track {
  border-radius: 50px;
  background-color: #000000;
  opacity: 0.38;
}

.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused {
  .MuiSelect-select {
    background: #ecf6ff;
  }
}

/* .MuiSelect-select~.MuiOutlinedInput-notchedOutline {
  top: 0;

  legend {
    display: none;
  }
} */

/* width */
::-webkit-scrollbar {
  height: 8px !important;
  width: 8px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: white !important;
  visibility: hidden;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #096dd94f !important;
  border-radius: 5px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0661c2af !important;
}