.scheduleMap {
  width: 100%;
  height: 100%;
  min-height: 300px;
  position: relative;

  .mapStatus {
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 11;
    padding: 15px;
    background: #ffffff;
    border: 1px solid #f0f0f0;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    min-width: 220px;

    &__text {
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.85);
      margin: 0 0 10px;

      img {
        width: 25px;
        margin-right: 10px;
        margin-bottom: -10px;
        height: 25px;
        object-fit: contain;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  & > div:last-child {
    height: 100% !important;

    & > div {
      height: 100% !important;
    }
  }
}

.baseSelected {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  // margin-top: 5px;

  p {
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #666666;
    margin-right: 12px;
  }

  .fromGroup-chips .MuiPaper-root {
    padding: 3px 12px;
    height: 26px;

    &:not(:last-child) {
      margin-right: 12px;
    }
  }
}

.fromGroup-chips--small .MuiPaper-root {
  padding: 4px 10px;
  height: 30px;
  font-size: 14px;

  &:not(:last-child) {
    margin-right: 8px;
  }
}

.btn-disabled {
  opacity: 50%;
  pointer-events: none;
  cursor: default;
}

.remainingBlock {
  margin-right: 0;
  margin-left: auto;
  width: 160px;
  text-align: center;

  &__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #666666;
    margin: 0 0 4px;
  }

  &__hrs {
    padding: 8px 15px;
    background: #ecf6ff;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.85);

    span {
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      display: block;
    }
  }
}

.mapUserInfo {
  position: absolute;
  left: 10px;
  z-index: 11;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 15%);
  border-radius: 4px;
  top: 105px;
  width: 220px;

  &__img {
    padding: 40px;
    //margin: 10px 10px;
    //height: 200px;

    img {
      height: 120px;
      width: 120px;
      object-fit: cover;
    }
  }

  &__details {
    max-height: 42vh;
    overflow-y: auto;
    padding: 15px;

    :last-child {
      margin-bottom: 0px;
    }
  }

  &__name {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
    margin: 0 0 22px;
    text-transform: capitalize;
    cursor: pointer;

    &.activeLink {
      color: #096dd9;
      text-decoration: underline;
      font-weight: 700;
    }
  }

  &__item {
    margin-bottom: 16px;

    &-title {
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
      color: #666666;
      margin: 0;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #333333;
      margin: 8px 0 0;
    }
  }
}
.matchScheduleBox-time {
  .fromGroup-chips {
    .MuiPaper-root {
      font-size: 15px;
      margin-right: 0 !important;
    }
  }
}

.scheduleFilter-btn {
  display: flex;
  gap: 15px;
}

@media only screen and (max-width: 901px) {
  .remainingBlock {
    margin-right: 0;
    margin-left: 0;
    margin-top: 20px;
  }
}
@media only screen and (max-width: 700px) {
  .scheduleFilter-btn {
    gap: 5px !important;
  }
}
@media only screen and (max-width: 700px) {
  .mapUserInfo {
    display: flex;
    bottom: 6px;
    top: 448px;
    width: 336px;
    overflow: auto;
    left: 6px;
    &__img {
      padding: 18px 8px;
    }
    &__details {
      display: flex;
      gap: 15px;
      /* overflow-y: hidden; */
      overflow-y: visible;
    }
    &__item {
      width: 115px;
    }
  }
}

@media only screen and (max-width: 375px) {
  .mapUserInfo {
    width: 287px;
  }
}

.matchtable {
  .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
    max-height: calc(100vh - 210px);
  }
  .css-15wwp11-MuiTableHead-root {
    position: sticky;
    top: 0;
    background: #f9f9f9;
    z-index: 1;
    height: 55px;
  }
}
