#dashboard {
  height: auto !important;
  .bbi-dbrd-designcanvas-container,
  .bbi-dashboarddesigner-designPanel {
    height: calc(100vh - 240px) !important;
  }
  .bbi-dashboarddesigner-surfaceContainer,
  .bbi-dashboard-designer {
    height: calc(100vh - 180px) !important;
  }

  .bbi-dashboard-designer,
  .boldbi-dashboarddesigner,
  .bbi-dashboarddesigner-designcanvas {
    height: auto !important;
  }
}
.dashboardTemplateSide {
  overflow: visible;
  height: auto;
  padding-bottom: 0;
}
