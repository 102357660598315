.top-btn-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
  overflow: auto;
  .languageBlock {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
  }
  div {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 15px;
    white-space: nowrap;
    margin-bottom: 1px;
  }
  .MuiBox-root {
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 8px 24px;
    border: 1px solid #fff;
    position: relative;
    cursor: pointer;

    //TODO: show blue dot when unreadCount > 0 at entity level
    // &::after {
    //   height: 10px;
    //   width: 10px;
    //   border-radius: 20px;
    //   background-color: #096dd9;
    //   content: "";
    //   position: absolute;
    //   left: 7px;
    //   top: 14px;
    // }
  }
  button {
    height: 42px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .active {
    // color: #096dd9;
    border: 1px solid #096dd9;
  }
}

.commentForm {
  padding-bottom: 0;
  border-radius: 0 !important;
  // textarea {
  //   height: 46px !important;
  //   overflow: hidden;
  // }
}

.compose-block {
  display: flex;
  gap: 10px;
  .compose-user-img {
    img {
      width: 30px;
    }
  }
  .compose-user-heading {
    display: flex;
    align-items: center;
    gap: 15px;
    margin: 8px 0;
  }
  .compose-user-name {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.85);
    margin: 0;
  }
  .compose-user-time {
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.85);
    margin: 0;
  }
  .compose-user-comment {
    padding: 0;
    width: 100%;
    whitespace: "pre-line";
    //line-break: anywhere;
    padding-right: 10px;
  }
}
.messageUser {
  margin-bottom: 12px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 8px 24px;
  display: flex;
  align-items: normal;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
}

.messageInternal-block {
  cursor: pointer;
  padding: 20px !important;
  margin-bottom: 10px !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  line-break: anywhere;
  .commentListItem__user-img {
    position: relative;
    display: flex;
    align-items: center;
    .active {
      height: 10px;
      width: 10px;
      border-radius: 20px;
      background-color: #096dd9;
      margin-right: 10px;
    }
  }

  .compose-user-name {
    font-weight: normal;
  }
  .compose-user-time {
    margin-left: auto;
    color: #333;
  }
  .messageTicket-number {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
    span {
      color: #333;
      font-size: 14px;
      background-color: rgba(255, 255, 255, 0.5);
      padding: 0 8px;
      box-shadow: 0px 0px 7px 0px #e2e2e2;
    }
  }
  .message-listingBlock {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
.messageInternal-block:hover {
  background-color: #f1f8fd;
}
.light-blue-bg {
  background-color: #ecf6ff;
}
// .messageInternal-block.whiteInternam-block {
//   background-color: #fff;
// }

.scroll-bar {
  overflow-x: hidden;
  max-height: 499px;
}

.userMapCart__contact {
  color: #096dd9;
}

.border-button {
  height: 25px;
  font-size: small;

  margin: 5px;
}

.commentForm-new {
  textarea {
    max-height: 150px;
    overflow-y: auto !important;
  }
}

.fromGroup-chip-active-message {
  margin-bottom: 12px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 8px 24px;
  display: flex;
  align-items: normal;
  background: rgba(186, 214, 253, 0.4);
  color: #096dd9;
  border: 1px solid rgba(113, 172, 255, 0.4);
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (max-width: 425px) {
  .messageUser {
    padding: 8px 0px;
  }
  .fromGroup-chip-active-message {
    padding: 8px 0px;
  }
  .top-btn-list {
    display: block;
    margin: 2px;
    overflow-x: auto;
    .MuiBox-root {
      font-size: 15px;
      padding: 20px 20px;
    }
    div {
      gap: 10px;
      max-height: 50px;
    }
    .extra-btn {
      margin-top: 15px;
      justify-content: end;
    }
  }
  .messageHeader {
    justify-content: space-between;
    margin-bottom: 20px !important;
  }
}
.scroll-message {
  max-height: calc(100vh - 250px);
  overflow-y: auto;
}
.messageHeader {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 15px;
}

.select-all {
  display: flex;
  align-items: center;
  margin: 0px 0px 10px 20px;
}
