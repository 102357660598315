.session {
  padding: 30px;
  .session-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-weight: 600;
      font-size: 24px;
    }
  }
  .gridTable table {
    .e-checkbox-wrapper .e-frame,
    .e-css.e-checkbox-wrapper .e-frame {
      width: 18px;
      height: 18px;
      line-height: 17px;
    }
  }
}

.sessionDetails {
  padding: 30px;
  max-height: calc(100vh - 88px);
  overflow: auto;
  .details-header {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .session-Title {
    font-size: 24px;
    font-weight: 700;
  }
  .details-tab {
    .tabs {
      list-style: none;
      display: flex;
      gap: 20px;
      padding-left: 2px;
      li {
        padding: 5px;
        font-size: 20px;
        font-weight: 400;
        cursor: pointer;
      }
      .active-li {
        color: #1976d2;
        border-bottom: 2px solid #1976d2;
      }
    }
  }
  .closeSession {
    float: right;
  }

  .session-summary {
    padding-top: 20px;
    .card-title {
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      margin-bottom: 10px;
    }
    .carDescription {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: #8c8c8c;
    }

    .summary-card {
      position: relative;
      padding: 15px;
      p {
        margin-bottom: 10px;
      }
      .css-46bh2p-MuiCardContent-root {
        padding: 0px;
      }
      .card-header {
        background: #096dd9;
        color: #fff;
        padding: 10px;
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
      }
      .sheduleDetails {
        padding: 10px 0px;
        display: flex;
        gap: 10px;
      }
      .sub-list {
        font-size: 16px;
        font-weight: 600;
      }
      .timeDetails {
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 20px;
      }
      .time-dropDown {
        margin-left: 0;
      }
      .userDetails {
        margin: 12px 0px;
        display: flex;
        gap: 10px;
        align-items: center;
      }
    }
    .signOut-procedure {
      display: grid;
      grid-template-columns: 3fr 1fr;
      margin-top: 22px;
      column-gap: 21px;
      @media only screen and (max-width: 1024px) {
        grid-template-columns: 2fr 1fr;
      }
    }
    .signIn-procedure {
      display: grid;
      grid-template-columns: 4fr 1fr;
      margin-top: 22px;
      column-gap: 12px;
      @media only screen and (max-width: 1024px) {
        grid-template-columns: 3fr 1fr;
      }
    }
  }
  .largeModel {
    .closeSession {
      font-size: 16px;
      font-weight: 500;
    }
  }
  button {
    text-transform: none;
    gap: 5px;
  }
}

.stepperStage {
  border: 1px solid #aaa;
  margin-bottom: 10px;
  box-shadow: 0 2px 8px #00000026;
  border-radius: 4px;

  ul {
    margin: 0;
    padding: 25px 55px;
    gap: 8px;
    display: flex;
    align-items: center;
    list-style-type: none;
    flex-wrap: wrap;

    li {
      background: #fff;
      border: 1px solid #d2d6dc;
      font-size: 14px !important;
      color: #8d8c8c45 !important;
      text-transform: capitalize;
      min-width: 28px;
      height: 52px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      z-index: 1;
      text-align: left;
      text-decoration: none;
      box-sizing: border-box;

      margin-right: 0px;
      padding: 25px;
      &:first-of-type {
        z-index: 13;
      }

      &:nth-child(2) {
        z-index: 12;
      }

      &:nth-child(3) {
        z-index: 11;
      }
      &:nth-child(4) {
        z-index: 10;
      }
    }
  }

  li:last-child {
    .stepperStage_item {
      &-link {
        &::before,
        &::after {
          display: none;
        }
      }
      &.active-onBoard {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        background: rgba(255, 77, 79, 0.2);
      }
    }
  }

  &_item {
    &.active-onBoard {
      border-color: #7ec4f6;
      background: #e5f5ff;
      .ui-breadcrumb__item-link::after {
        background: #e5f5ff;
        border-color: #7ec4f6;
      }
    }
  }
  .linkForm {
    color: #096dd9;
    cursor: pointer;
  }
}

.stepperStage_item.active-onBoard .stepperStage_item-link::after {
  background: #e5f5ff;
  border-color: #7ec4f6;
}
.stepperStage_item-link::after {
  content: "";
  background: #fff;
  border-right: 1px solid #d2d6dc;
  border-top: 1px solid #d2d6dc;
  height: 40px;
  width: 40px;
  display: block;
  transform: rotate(45deg);
  position: absolute;
  right: -18px;
  border-radius: 8px;
  top: 4px;
}

.stepperStage_item-link {
  color: #ed8154;
  text-decoration: none;
  margin-right: 12px;
}
.stepperStage_item-link::before {
  content: "";
  background: #fff;
  height: 40px;
  width: 40px;
  display: block;
  transform: rotate(45deg);
  position: absolute;
  right: -26px;
  z-index: 0;
  border-right: 1px solid #d2d6dc;
  border-top: 1px solid #d2d6dc;
  border-radius: 8px;
  top: 4px;
}
.p-25 {
  padding: 25px;
}
.back-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #1976d2;
  cursor: pointer;
}

.sessionForms {
  .intakeForm_inner {
    margin: 0 7px;
  }
  .cardSlider {
    .slider {
      height: 220px !important;
      .documentCard__btn {
        padding: 9px 18px;
        justify-content: flex-end;
      }
      .documentCard__img {
        height: 140px;
      }
    }
    .carousel__slide {
      height: 220px;
      padding: 0px;
      // margin: 4px;
    }
  }
  .session-Request {
    margin-top: 20px;
    .formRequest-title {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      text-align: left;
      margin: 20px 0px;
    }
    .tableResponsive {
      th {
        font-size: 18px;
        font-weight: 500;
      }
      tbody {
        tr {
          box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
          border-radius: 4px !important;
        }
      }
    }
  }
}
.flex {
  display: flex;
  align-items: center;
  gap: 10px;
}
.ClientSession {
  td,
  span,
  p {
    font-size: 14px;
  }
}
.statusBox {
  border-radius: 25px;
  padding: 3px 10px;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  font-weight: 400;
  width: -moz-fit-content;
  width: fit-content;
  border: 3px solid #6bb283;
  background-color: white;
}
.session-preview {
  .MuiDialog-paper {
    overflow: hidden !important;
  }
  .sd-body.sd-body--static {
    max-height: 500px;
  }
}
.session-filters {
  .DatePickerFilter {
    margin-top: 8px;
    margin-right: 12px;
  }
}
.margin-R {
  margin-right: 12px;
}
