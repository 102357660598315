.MuiDrawer-paper.MuiDrawer-paperAnchorLeft {
  max-width: 320px;
  width: 100%;

  & > div {
    max-width: 320px;
    width: 100%;
  }
}

.sideMenu {
  .MuiDrawer-root {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 111;
  }

  &__close {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 26px;
      height: 26px;
      color: #1976d2;
      fill: #1976d2;
      cursor: pointer;
    }
  }

  &__header {
    padding: 12px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-inner {
      display: flex;
      align-items: center;
    }
  }

  .MuiDrawer-paper.MuiDrawer-paperAnchorLeft {
    & > .MuiBox-root {
      width: 320px;
    }
  }

  &__logo {
    height: 44px;
  }

  &__nav {
    padding: 0;
    margin: 20px 0;

    .MuiButtonBase-root {
      background-color: transparent !important;
      padding: 10px 20px;

      .MuiTypography-root {
        font-family: "Roboto", sans-serif;
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        color: rgba(0, 0, 0, 0.85);
      }

      .MuiListItemIcon-root {
        min-width: 42px;
        padding: 2px;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      &:hover {
        background-color: #ecf6ff !important;

        .MuiTypography-root {
          //color: #1976d2;
          font-weight: 500;
          font-size: 17px;
          line-height: 25px;
          color: rgba(0, 0, 0, 0.85);
        }
      }
    }
  }

  &__footer {
    padding: 20px;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    cursor: pointer;
    @media only screen and (max-width: 1117px) {
      position: static;
    }

    &-icon {
      min-width: 42px;
      padding: 2px;
      display: flex;
      align-items: center;
    }

    svg {
      width: 20px;
      height: 20px;
    }

    .MuiTypography-root {
      font-family: "Roboto", sans-serif;
      font-weight: 500;
      font-size: 17px;
      line-height: 25px;
      color: rgba(0, 0, 0, 0.85);
    }
  }
}
@media only screen and (max-width: 1117px) {
  .MuiDrawer-paper.MuiDrawer-paperAnchorLeft {
    width: 250px;
  }
}
