.automationCardBlock {
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 18px;

    @media only screen and (max-width: 991px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__title {
    h4 {
      color: #1e1e1e;
      font-family: "Roboto", sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      margin: 0;
    }
    /* 120% */

    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.automationCard {
  // @media only screen and (max-width: 1440px) {
  //  width: 52.1vw;
  // }
  // @media only screen and (max-width: 1024px) {
  //  width: 41.3vw;
  // }
  // @media only screen and (max-width: 991px) {
  //   width: 41.5vw;
  // }
  // @media only screen and (max-width: 800px) {
  //   width: 60.5vw;
  // }
  &__inner {
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    padding: 20px;
    //   width: 59vw;
    //   @media only screen and (max-width: 1440px) {
    //  width: 52vw;
    // }
    //   @media only screen and (max-width: 1024px) {
    //     width: 41.15vw;
    //   }
    //   @media only screen and (max-width: 991px) {
    //     width: 41.15vw;
    //   }
    //   @media only screen and (max-width: 800px) {
    //     width: 60.15vw;
    //   }
  }
  &__arrow {
    padding: 20px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  &__title {
    color: #1e1e1e;
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin: 0 0 20px;
  }
  &__fields {
    display: grid;
    row-gap: 15px;
    grid-template-columns: 140px calc(100% - 150px);
    align-items: center;
    & > label {
      color: rgba(0, 0, 0, 0.85);
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px; /* 112.5% */
    }
    fieldset {
      border-radius: 2px;
      border: 1px solid var(--neutral-5, #d9d9d9);
      box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
      &:hover {
        border-color: #d9d9d9 !important;
      }
    }
    .MuiFormControl-root {
      max-width: 200px;
      .MuiInputBase-root {
        width: 100% !important;
      }
    }
  }
}

.automationSidebar {
  .automationSidebar__innerItem-title,
  .automationSidebar__innerItem-selected {
    align-items: flex-start;
    .icons-Container{
      display:flex;
      align-items:flex-start;
      gap:10px;
    }
    .icon {
      flex: none;
    }
    p {
      word-break: break-word;
    }
  }
  padding: 20px 15px;
  &__item {
    margin-bottom: 15px;
    &-title {
      color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      margin-bottom: 10px;
      word-break: break-word;
    }
  }
  &__innerItem {
    display: grid;
    gap: 10px;
    padding: 0 0 0 20px;
    margin: 0;
    list-style-type: none;
    cursor: pointer;
    &-title {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      gap: 10px;
      p {
        color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        display: flex;
        align-items: center;
        gap: 10px;
      }
      button {
        padding: 0;
        margin: 0;
        border: none;
        background: transparent;
      }
    }
    &-selected {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      gap: 10px;
      p {
        color: var(--character-primary-85, rgb(9, 109, 217, 0.9));
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        display: flex;
        align-items: center;
        gap: 10px;
      }
      button {
        padding: 0;
        margin: 0;
        border: none;
        background: transparent;
      }
    }
  }
}

.ruleName input {
  height: 5px;
  width: 250px;
}

.alertTimeError {
  color: rgb(211, 47, 47);
  font-size: 14px;
}

.ruleStatus {
  .active {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #2ac960;
  }
  .onHold {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color:#FFC701
  }
  .off {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color:#696969
  }
}
.ruleAction {
  .on-active {
    color: #096dd9;
    background-color: #fafafa;
    border: 1px solid #096dd9;
  }
  .onHold-active {
    color: rgb(211, 47, 47);
    border: 1px solid rgb(211, 47, 47);
    background-color: #fafafa;
  }
}