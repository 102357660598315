.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  gap: 5px;
  margin: 30px 40px 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 500px;
  position: relative;

  .carousel {
    width: 100%;
  }

  .carousel__slide {
    padding-bottom: 40px !important;
  }

  &__item {
    display: flex;
    justify-content: center;

    &-link {
      margin: 0 !important;
      height: 35px;
      width: 36px;
      padding: 0;
      border: 1px solid #ddd;
      background: transparent;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      color: #000;
      font-weight: 500;
      cursor: pointer;

      &.active {
        background-color: #096dd9;
        border-color: #096dd9;
        color: #fff;
      }

      &.carousel__back-button {
        border-color: #096dd9;
        position: absolute;
        left: -40px;
        top: 0;

        svg {
          height: 14px;
          width: 14px;

          path {
            stroke: #096dd9;
          }
        }

        &:disabled {
          opacity: 30%;
          cursor: auto;
        }
      }

      &.carousel__next-button {
        border-color: #096dd9;
        position: absolute;
        top: 0;
        left: auto;
        right: -40px;

        svg {
          height: 14px;
          width: 14px;

          path {
            stroke: #096dd9;
          }
        }

        &:disabled {
          opacity: 30%;
          cursor: auto;
        }
      }
    }
  }
}