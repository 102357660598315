.documentCard {
  // filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
  background: #ffffff;
  border: 0.5px solid #c3bebe;
  width: 100%;
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;

  &__img {
    background: #f0f0f0;
    height: 155px;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 15px 0;
    overflow: hidden;
    box-sizing: border-box;

    &-card {
      filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
      background: #ffffff;
      border-radius: 5px;
      text-align: left;
      padding: 12px;
      background: #ffffff;
      border-radius: 5px 5px 0 0;
      text-align: left;
      padding: 25px;
      height: 100%;
      box-sizing: border-box;
      width: 100%;
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
    }
  }

  &__btn {
    background: #fff;
    border: none;
    padding: 12px 18px;
    font-weight: 500;
    font-size: 15px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #434343;
    justify-content: space-between;
    border-top: 1px solid #c3bebe;
    @media only screen and (max-width: 560px) {
      padding: 8px 5px;
      font-size: 13px;
      gap: 3px;
    }

    img {
      width: 35px;
      height: 35px;
      object-fit: contain;
    }

    span {
      padding: 8px 20px;
      border-radius: 5px;
      background-color: #096dd9;
      color: #fff;
      font-size: 14px;
      line-height: 16px;
      @media only screen and (max-width: 560px) {
        width: 100%;
        text-align: center;
      }
    }
  }
}

.assessMentTable {
  .MuiDataGrid-main {
    overflow: initial;
  }

  .MuiDataGrid-root {
    border: none;

    .MuiDataGrid-columnSeparator {
      display: none;
    }

    .MuiDataGrid-columnHeaders {
      border: none;
    }

    .MuiDataGrid-columnHeader {
      padding: 0 16px;
    }

    .MuiDataGrid-virtualScroller {
      padding: 0 5px;
    }
  }

  .MuiDataGrid-columnHeaderTitleContainer {
    justify-content: space-between !important;

    .MuiDataGrid-columnHeaderTitle {
      font-family: "Roboto", sans-serif;
      font-weight: 500;
      font-size: 18px;
      line-height: 32px;
      color: rgba(0, 0, 0, 0.85);
      white-space: pre;
    }
  }

  .MuiDataGrid-virtualScroller {
    .MuiDataGrid-virtualScrollerRenderZone {
      gap: 8px;
    }

    .MuiDataGrid-row {
      background: #ffffff;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
      border-radius: 4px !important;

      &:nth-child(odd) {
        background-color: #ecf6ff;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
        height: 40px;
      }

      .MuiDataGrid-cell {
        padding: 8px 16px;
        text-align: left;
        font-family: "Roboto", sans-serif;
        white-space: pre;
        vertical-align: middle !important;
        justify-content: flex-start;
        font-size: 14px;
        border: none;
      }
    }
  }
}

.smallModel--assessment {
  .MuiDialog-paper {
    max-width: 700px;
    width: 100%;
    max-height: 610px;
  }
  .content {
    height: 500px;
    overflow: auto;
  }
}
.smallModel--intakeforms {
  .MuiDialog-paper {
    max-width: 700px;
    width: 100%;
    max-height: 725px;
  }
}
.fromGroup--Assesment {
  padding: 15px 20px;
}
.modelBlockTitle {
  color: rgb(9, 109, 217);
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  margin: 0 0 12px;
}
.pageTemplate--Assessment {
  padding: 45px 30px;
}
.smallModel--assessment {
  .sd-description {
    font-size: 14px !important;
  }
}

.assessmentModel__footer {
  right: 0;
  left: 0;
  text-align: end;
  border-top: 1px solid #ddd;
  padding: 7px;
  position: absolute;
  bottom: 0;
  .send-btn {
    background-color: #096dd9;
    color: #fff;
    border: 1px solid #096dd9;
  }
  .send-btn:hover {
    background: #fff;
    color: #096dd9;
  }
}

// table {
//   td {
//     padding: 5px;
//   }
// }
.statusChip {
  border: 1px solid #096dd9;
  border-radius: 25px;
  background: #eff7ff;
  color: #096dd9;
  font-weight: 600;
  padding: 4px 15px;
  line-height: 20px;
  width: fit-content;
  font-size: 14px;
}

.gridTable {
  border: none;
  .e-groupdroparea {
    border: 1px solid #096dd9;
    border-radius: 5px;
    margin-bottom: 15px;
    background: #eff7ff;
    color: #096dd9;
    font-weight: 600;
  }
  .e-gridpager {
    border: none !important;
  }
  .e-gridheader {
    border: none !important;
    .e-rhandler {
      border: none !important;
    }
  }
  table {
    border-spacing: 0 8px;
  }
  tbody {
    tr {
      background: #ffffff;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
      border-radius: 4px !important;
      &:nth-of-type(odd) {
        background-color: #ecf6ff;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
        height: 30px;
      }
      td {
        font-family: "Roboto", sans-serif !important;
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 32px !important;
        color: rgba(0, 0, 0, 0.85) !important;
        vertical-align: middle !important;
      }
    }
  }
}

/*7 August 2023*/
.sv-components-row {
  // height: 440px;
  overflow: auto;
}

.sv-components-column {
  display: none;
}
.sd-row ~ .sd-row .sd-panel--as-page {
  margin-top: 0 !important;
  margin-bottom: 20px;
}

.sd-title.sd-container-modern__title {
  position: relative;
  z-index: 1;
}

.buttonsCard {
  width: 190px;
  height: 170px;
  // box-shadow: rgb(0 0 0 / 24%) 0px 1px 4px;
  text-align: center;
  padding: 25px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 16px;
    color: rgb(9, 109, 217) !important;
    padding: 8px;
    border-radius: 5px;
    background: transparent !important;
    max-width: 100% !important;
    width: 100%;
    margin: 0 !important;
    gap: 5px;
    svg {
      height: 20px;
      width: 20px;
    }
  }
}

.documentsBlock {
  display: grid;
  gap: 25px;
  padding: 24px;
  // min-height: 500px;
  // overflow-y: auto;
  &__item {
    border: 1px solid #cdd9e5;
    border-radius: 8px;
    overflow: hidden;
    &-head {
      padding: 10px 18px;
      border-bottom: 1px solid #cdd9e5;
      background-color: #eff7ff;
    }
    &-title {
      color: #096dd9;
      font-weight: 600;
      font-size: 16px;
      margin: 0;
      span {
        font-weight: 700;
        color: #000;
      }
    }
    &-body {
      padding: 15px 18px;
    }
    &-que {
      color: #000;
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 0px;
      display: flex;
      gap: 12px;
      span {
        font-weight: 700;
      }
    }
    &-ans {
      color: #586f89;
      font-weight: 500;
      font-size: 14px;
      margin: 0;
      display: flex;
      gap: 12px;
      span {
        font-weight: 700;
        color: #000;
      }
    }
  }
}
.comparisonTable {
  .innerTabs {
    margin: 0px;
  }
  .comparisonTableInner {
    height: calc(100vh - 310px);
    overflow: auto;
    table {
      thead {
        tr {
          th:first-child {
            width: 120px;
          }
        }
      }
    }
  }
}
.resetImage {
  height: 30px;
  width: 30px;
}
@media (max-width: 1440px) {
  .gridTable {
    tbody {
      tr {
        td {
          font-size: 14px !important;
          line-height: 20px !important;
        }
      }
    }
  }
  .resetImage {
    height: 20px;
    width: 20px;
  }
}
