.pageTemplateSide {
  padding: 32px 15px;
}

.disable-chip {
  pointer-events: none;
  opacity: 70%;
}

.setDate {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;

  span {
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: inline-block;
    margin-left: 10px;
  }

  svg {
    height: 20px;
    width: 20px;
    color: #096dd9;
  }
}

.text-blue {
  color: #096dd9;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.MuiTableCell-alignRight {
  text-align: right !important;
}

.button {
  font-family: "Roboto", sans-serif;
  background-color: #096dd9;
  padding: 6px 16px;
  border-radius: 8px;
  min-height: 32px;
  color: #fff;
  box-shadow: none !important;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;

  svg {
    font-size: 22px;
  }

  &--large {
    padding: 10px 20px;
  }
}

.b-0 {
  bottom: 0;
}

.linkBtn {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #096dd9;
  border: none;
  background: transparent;
  cursor: pointer;
}

.filterGroup-btn {
  button {
    font-family: "Roboto", sans-serif;
    background-color: #096dd9;
    padding: 6px 16px;
    border-radius: 8px;
    height: 40px;
    color: #fff;
    box-shadow: none !important;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;

    svg {
      font-size: 22px;
      height: 18px;
      width: 18px;
    }

    &:last-child {
      .MuiButton-startIcon {
        margin-right: 0;
      }
    }
  }
}

.userSmallCircle {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
}

.verifiedAddressInList {
  height: 25px;
  width: 25px;
  margin-left: -25px;
  margin-bottom: -10px;
}

.userList-name {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.clientBoardSlider {
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  // gap: 10px;
  // overflow-x: auto !important;
  // scroll-behavior: smooth;
  // width: 100%;
  box-sizing: border-box;
  // overflow: auto;

  & > div {
    width: 100%;
    // overflow: auto;
  }
}

.allStageCards {
  display: flex;
  width: 100%;
  // overflow: auto !important;
}
.allStageCardsBoard {
  height: 50vh;
}
// .cardBlockClient {
//   // height: 68vh;
// }
.cardBlock {
  // overflow-x: auto;
  // min-height: 70vh;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &__title {
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #000000;

    span {
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      color: rgba(0, 0, 0, 0.85);
      display: block;
      margin-top: 5px;
    }
  }
}

.cardBox {
  background: #fff;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 15%);
  cursor: pointer;

  &__image {
    width: 100%;
    height: 180px;
    object-fit: cover;
    overflow: hidden;
    width: 152px;
    height: 152px;
    left: 76px;
    top: 16px;
    border-radius: 4px;
    margin: 15px auto;
  }

  &__title {
    padding: 0 24px 16px;
    margin: 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    font-family: "Roboto", sans-serif;
    border-bottom: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &__info {
    padding: 16px 10px;

    & > div:last-child {
      margin-bottom: 0 !important;
    }
  }

  &__infoList {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    &-item {
      width: 50%;

      &:nth-child(even) {
        text-align: right;
      }
    }
    .cardInsurance-provider {
      display: flex;
      width: 350px;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }
  }

  &__info-title {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-size: 13px;
    line-height: 16px;
    color: #666666;
    margin-bottom: 5px;
  }

  &__info-text {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.85);
    margin: 0;
  }
}

.react-calendar-heatmap-month-label,
.react-calendar-heatmap-weekday-label {
  font-size: 8px !important;
  fill: #000 !important;
}

.react-calendar-heatmap .color-filled {
  fill: #6bb283 !important;
}

.react-calendar-heatmap .color-pending {
  fill: #096dd9 !important;
  stroke: red;
  stroke-width: 1px;
  stroke-dasharray: 1, 1.5;
  stroke-linecap: round;
}

.react-calendar-heatmap .color-square-0 {
  fill: #6bb283 !important;
}

.react-calendar-heatmap .color-square-1 {
  fill: #096dd9 !important;
}

.react-calendar-heatmap .color-square-2 {
  fill: #000 !important;
}

.listingTable {
  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 22px;
  }

  &__title {
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    width: max-content;
    color: #000000;
    margin: 0;
  }

  .tableResponsive {
    box-shadow: none;
    padding: 5px;
  }

  table {
    border-spacing: 0 8px;

    tr {
      min-height: 48px;
      margin-bottom: 8px;
    }

    th,
    td {
      padding: 8px 16px;
      text-align: left;
      font-family: "Roboto", sans-serif;
      white-space: pre;
      vertical-align: middle !important;
    }

    .MuiTableCell-head {
      font-family: "Roboto", sans-serif;
      font-weight: 500;
      font-size: 18px;
      line-height: 32px;
      color: rgba(0, 0, 0, 0.85);
      white-space: pre;
    }

    tbody {
      tr {
        background: #ffffff;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
        border-radius: 4px !important;

        tr:nth-child(odd) {
          background: #ecf6ff;
        }
      }

      td {
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        vertical-align: middle !important;
        span {
          white-space: break-spaces;
        }
      }
    }
  }
}

.selectedIn-fileBlank {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
  margin-top: 10px;
}
.selectedIn-fileBox {
  max-height: 400px;
  overflow: auto;
  padding-top: 15px;
}
.selectedIn-file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  // margin-left: 12px;

  h1 {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
    margin: 0;
    line-break: anywhere;
  }

  span {
    cursor: pointer;

    svg {
      color: #096dd9;
      height: 20px;
      width: 20px;
    }
  }
}

.fileUploadBlock {
  position: relative;
  text-align: center;
  background: #ecf6ff;
  border: 1px solid #096dd9;
  box-sizing: border-box;
  & > .MuiBox-root {
    position: absolute;
    border: none;
    border-radius: 2px;
    padding: 0;
    top: 10px;
    bottom: 0;
    left: 0;
    right: 0;
    & > .MuiBox-root {
      display: none !important;
    }
  }

  .MuiFormControl-root {
    width: 100%;
    padding: 30px;
    .css-17ru0vy-MuiInputBase-root-MuiOutlinedInput-root {
      height: 50px;
    }
  }

  .MuiTypography-root {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
  }

  .MuiButtonBase-root.MuiButton-root {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0;
    opacity: 0;
  }
  .uploadIcon {
    font-size: 50px;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
}

.matchBtnBlock button {
  &.button-border {
    background: transparent !important;
    border: 1px solid #096dd9 !important;
    color: #096dd9 !important;
    font-weight: 600;
  }
}

.clientBoardSlider-module {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;

  .sliderNavBtn {
    padding: 0px;
    height: 30px;
    width: 30px;
    border: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #096dd9 !important;
    border-radius: 50%;
    flex: 30px 0px 0px;
    box-sizing: border-box;
    min-width: 30px;

    &.carousel__next-button {
      right: -38px;
      left: auto;
    }

    svg {
      height: 14px;
      width: 14px;

      path {
        stroke: #fff;
      }
    }

    &:disabled {
      svg path {
        stroke: #ccc;
      }
    }
  }
}

.detailsCard {
  .client_new_slider {
    .cardSlider {
      .carousel__slide {
        height: 300px;
      }
    }
    .parentSlide {
      margin-bottom: 20px;
    }
  }
}

.cardSlider {
  width: calc(100% - 84px);
  margin: 0 42px;
  position: relative;

  .cardBox {
    margin: 0 10px;
    // height: auto;
    height: 100%;
  }

  .carousel__slide {
    padding: 0 !important;
    height: 390px;
  }

  .sliderNavBtn {
    position: absolute;
    padding: 0;
    height: 30px;
    width: 30px;
    border: none;
    background: transparent;
    top: 50%;
    left: -38px;
    transform: translateY(-45%);
    border: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 50%;

    &.carousel__next-button {
      right: -38px;
      left: auto;
    }

    svg {
      height: 14px;
      width: 14px;

      path {
        // stroke: #096dd9;
      }
    }

    &:disabled {
      svg path {
        stroke: #ccc;
      }
    }
  }
}

.parentSlider {
  margin: 0;
}

.formCardSlider {
  width: calc(100% - 84px);
  margin: 0 42px;
  position: relative;

  .cardBox {
    margin: 0 10px;
    // height: auto;
    height: 100%;
  }

  .carousel__slide {
    padding: 0 !important;
    height: 250px;
  }

  .sliderNavBtn {
    position: absolute;
    padding: 0;
    height: 30px;
    width: 30px;
    border: none;
    background: transparent;
    top: 50%;
    left: -38px;
    transform: translateY(-45%);
    border: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 50%;

    &.carousel__next-button {
      right: -38px;
      left: auto;
    }

    svg {
      height: 14px;
      width: 14px;

      path {
        // stroke: #096dd9;
      }
    }

    &:disabled {
      svg path {
        stroke: #ccc;
      }
    }
  }
}

.listingTable__header.d-block {
  display: block !important;
}
@media only screen and (max-width: 1100px) {
  .listingTable__header {
    display: block;
  }
}
@media only screen and (max-width: 1024px) {
  .pageTemplateSide {
    padding: 40px 15px;
  }
}
@media only screen and (max-width: 1024px) {
  .pageTemplate {
    padding: 40px 15px;
  }
  .pageTemplateSide {
    padding: 40px 15px;
  }

  .cardBlock__title {
    font-size: 22px;
    line-height: 30px;
  }

  .listingTable__title {
    font-size: 22px;
    line-height: 30px;
  }

  .cardSlider {
    width: calc(100% - 60px);
    margin: 0 30px;
  }
}

@media only screen and (max-width: 560px) {
  .cardBlock__header {
    flex-direction: column;
    flex-direction: column;
    align-items: flex-start;
  }

  .cardBlock__title {
    margin-bottom: 10px;
  }

  .listingTable__header {
    flex-direction: column;
    flex-direction: column;
    align-items: flex-start;
  }

  // .listingTable__title {
  //   margin-bottom: 10px;
  // }

  .cardSlider {
    width: calc(100% - 84px);
    margin: 0 42px;
  }
  .cardSlider .cardBox {
    height: auto;
  }
}

.cardSliderModule {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 8px;
}
.sliderNavBtn {
  padding: 0px;
  height: 30px;
  width: 30px;
  border: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #096dd9 !important;
  border-radius: 50%;
  flex: 30px 1;
  box-sizing: border-box;
  min-width: 30px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 30px;
  svg path {
    stroke: #fff;
  }
}
.fileUploadModal {
  .MuiDialog-paper {
    max-width: 600px !important;
  }
}

.uploadIcon {
  &-img {
    position: absolute;
    border: none;
    border-radius: 2px;
    padding: 0;
    top: 20%;
    left: 40%;
  }
}
