.mapWrapper {
  height: calc(100vh - 210px);

  .innerTabs {
    margin: 0 0 22px;
  }
  .splitter-layout {
    left: 0;
    & > .layout-splitter {
      flex: 0 0 auto;
      width: 4px;
      height: 100%;
      cursor: col-resize;
      background-color: #ccc;
      background: transparent;
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc;
    }
    .layout-pane-primary {
      background-color: transparent;
    }
  }
}

// .cardLevelBlock {
//   margin-bottom: 12px;
//   background: #ffffff;
//   box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
//   border-radius: 4px;
//   padding: 8px 24px;
// }

.cardLevelBlock {
  // display: flex;
  // align-items: center;
  // gap: 12px;
  // position: relative;
  // padding-top: 5px;
  // border-top: 1px solid rgb(190, 190, 190);
  // padding: 5px 0;
  overflow: hidden;
  // overflow: auto;
  table {
    width: 100%;
    border-collapse: collapse;

    .map-thead {
      th {
        padding: 5px;
        width: 75px;
        min-width: 75px;
        &:first-child {
          width: 35px;
          min-width: 35px;
        }
        &:nth-child(2) {
          width: 100%;
        }
      }
    }
    .plan-thead {
      th {
        padding: 5px;
        // width: 25px;
        // min-width: 25px;
        // &:first-child {
        //   width: 25px;
        //   min-width: 25px;
        // }
        // &:nth-child(2) {
        //   width: 25px;
        //   min-width: 25px;
        // }
        // &:nth-child(3) {
        //   width: 25px;
        //   min-width: 25px;
        // }
        // &:nth-child(4) {
        //   width: 100%;
        // }
      }
    }
    .plan-tbody {
      border-top: 1px solid #096dd9;
      td {
        padding: 10px 5px;
        width: auto;
        // &:first-child {
        //   width: 35px;
        // }
        // &:nth-child(2) {
        //   width: 35px;
        //   min-width: 45px;
        // }
        // &:nth-child(3) {
        //   width: 100%;
        // }
      }
    }
    tbody {
      border-top: 1px solid #096dd9;
      td {
        padding: 10px 5px;
        // width: 75px;
        // &:first-child {
        //   width: 35px;
        // }
        // &:nth-child(2) {
        //   width: 100%;
        // }
      }
    }
    .userMapCart__img {
      position: relative;
      overflow: visible;
      .verifiedIcon {
        top: unset;
        left: unset;
        right: 0;
        bottom: 0;
        height: 16px;
      }
    }
    .userMapCart__statusBox {
      display: flex;
      flex-direction: column;
      font-size: 16px;
      // align-items:  center;
      justify-content: space-between;
      text-align: left;
      .top-level-title {
        white-space: nowrap;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
      }
      .text-center {
        text-align: center;
      }
    }
  }
}

.userMapCart {
  margin-bottom: 12px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 8px 12px;
  display: flex;
  align-items: normal;
  cursor: pointer;
  flex-direction: column;
  // justify-content: center;
  // .top-level-title {
  //   font-size: 10px;
  //   position: absolute;
  //   top: 0;
  // }

  &__img {
    display: flex;
    height: 32px;
    width: 32px;
    overflow: hidden;
    border-radius: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 32px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &__name {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    align-items: center;
    // width: 100%;
    gap: 10px;
    text-align: center;
    justify-content: space-between;
    span {
      font-weight: 500;
    }
  }

  &__contact {
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
    justify-content: right; //space-between;
    span {
      font-weight: 400;
    }
  }

  &__statusBox {
    display: flex;
    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: auto;
      font-size: 16px;

      &:not(:last-child) {
        padding-right: 10px;
        margin-right: 10px;
        // border-right: 2px solid #3399ff;
      }
      .top-level-title {
        // font-size: 14px;
        white-space: nowrap;
        font-weight: 400;
      }
    }

    &__name {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.85);
    }
  }
}

.verifiedIcon {
  position: absolute;
  left: 16px;
  top: 24px;
}

.border-box {
  border-top: 2px solid #3399ff;
  margin: 15px 0;
  padding-top: 15px;
}

.border-dot {
  border-top: 1px solid grey;
  margin: 5px 0;
  padding-top: 5px;
}

.fromGroup-chip-active {
  margin-bottom: 12px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 8px 12px;
  display: flex;
  align-items: normal;
  // gap: 12px;
  cursor: pointer;
  // background: #daedff;
  background: rgba(186, 214, 253, 0.4);
  color: #096dd9;
  border: 1px solid rgba(113, 172, 255, 0.4);
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
}

.userClientLevel,
.userClientblock {
  flex-direction: row;
  gap: 12px;
}

.mapUserList {
  height: 100%;
  overflow-y: auto;
  padding: 8px;
}
.mapUserList::-webkit-scrollbar {
  width: 8px !important;
}

.mapDetailsTemplate {
  .scheduleMap {
    .gmnoprint {
      left: auto !important;
    }
  }
}

.filterGroup-btn {
  display: flex;
  justify-content: flex-end;
  gap: 4px;
  // .MuiButtonGroup-firstButton {
  //   border-top-left-radius: 8px !important;
  //   border-bottom-left-radius: 8px !important;
  // }
}
.selected-filtersButtons {
  .border-button {
    background-color: transparent !important;
    color: #096dd9 !important;
    border: 1px solid #096dd9 !important;
  }
}

/*close Button CSS*/
.closeBtn {
  position: absolute;
  right: -8px;
  top: -10px;
  background-color: rgb(9, 109, 217);
  margin-left: 6px;
  border-radius: 29px;
  height: 16px;
  width: 16px;
  line-height: 22px;
  color: aliceblue;
}
.closeBtn svg {
  font-size: 14px;
}
.p-0{
  padding: 0px;
}
.p-7{
  padding: 7px;
}