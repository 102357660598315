.survayFormModule {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  // align-items: center;
  // justify-content: center;
  // .sd-title {
  //   position: relative;
  //   z-index: 1;
  // }
  .sd-logo {
    &__image {
      height: 89px !important;
      object-fit: contain !important;
      margin-top: 0 !important;
    }
  }
  // .sv-components-row {
  //   height: calc(100vh - 296px);
  // }

  label {
    background-color: white;
    padding-left: 4px;
    padding-right: 4px;
  }
  // .sd-description{
  //   height: 85px;
  //   overflow-y: auto;
  // }
}

.surveyTest {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
.TestInner {
  text-align: center;
  padding: 50px;
  box-shadow: 0 0 6px #ccc;
  border-radius: 8px;
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.surveyTest .testTitle {
  font-size: 34px;
  color: #333;
  margin-bottom: 20px;
  font-weight: 700;
}
.TestInner div {
  color: rgb(9, 109, 217);
  cursor: pointer;
}

.bef {
  display: flex;
  justify-content: end;
  padding: 16px;
}
@media screen and (max-width: 420px) {
  .bef {
    padding-left: 15px;
  }
}

.survayFormModule {
  #demo-simple-select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    left: 4px;
  }
}

.lang-drop {
  width: 140px;
}
