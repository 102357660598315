.search-block {
  max-width: 325px;
  height: 32px;
  width: 300px;

  &__icon {
    padding: 0 12px;
  }

  .MuiInputBase-root {
    width: 100%;
  }

  input {
    height: 32px;
    box-sizing: border-box;
    border: 1px solid #d9d9d9;
    background-color: transparent;
    border-radius: 8px;
    font-size: 16px;
    padding-left: 40px;

    &::placeholder {
      color: #000000;
      opacity: 1;
    }
  }
}

.mobileSearch {
  display: none;
  @media only screen and (max-width: 1160px) {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
  }
}

.rdrCalendarWrapper {
  color: #000000;
  font-size: 12px;
  max-width: 450px;
  width: 100%;
  position: absolute;
  right: 0;
  z-index: 11;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 15%);
  .rdrDateDisplayWrapper {
    display: none;
  }
  .rdrMonthAndYearWrapper {
    background: #096dd9;
    padding: 0;
    height: 40px;
    select {
      color: #fff;
      option {
        color: #000;
      }
    }
    .rdrMonthPicker,
    .rdrYearPicker {
      position: relative;
      &:after {
        content: "";
        height: 8px;
        width: 8px;
        display: block;
        border-left: 2px solid #fff;
        border-bottom: 2px solid #fff;
        position: absolute;
        right: 6px;
        top: 12px;
        transform: rotate(-45deg);
      }
    }
  }
}

.mainHeader {
  padding: 8px 25px;
  height: 80px;
  z-index: 1;
  &__toggle {
    margin: 0;
    margin-right: 24px;
    padding: 0;

    &:hover {
      background: transparent;
    }
  }

  &__logo {
    height: 64px;
    width: auto;
  }

  .search-block {
    max-width: 325px;
    height: 32px;
    width: 300px;

    &__icon {
      padding: 0 12px;
    }

    .MuiInputBase-root {
      width: 100%;
    }

    input {
      height: 32px;
      box-sizing: border-box;
      border: 1px solid #d9d9d9;
      background-color: transparent;
      border-radius: 8px;
      font-size: 16px;
      padding-left: 40px;

      &::placeholder {
        color: #000000;
        opacity: 1;
      }
    }
  }

  &__menu {
    margin: 0 65px;
  }

  &__mobileToggle {
    display: none;
    margin: 0;
    padding: 0;
    margin-left: 12px;
    align-items: center;
    justify-content: center;
  }
}

.mobile-menu {
  display: none;
}

.userBox {
  display: flex;
  margin-right: 22px;

  &__image {
    height: 34px;
    width: 34px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 10px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &__name {
    font-size: 18px;
    color: #000000;
    line-height: 18px;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
  }
}

.logout-icon {
  cursor: pointer;
  height: 35px;
  width: 35px;
  border-radius: 10px;
  overflow: hidden;
  background: #1976d2;
  padding: 8px;
  fill: #fff;
}

@media only screen and (max-width: 1640px) {
  .mainHeader {
    &__menu {
      margin: 0px 28px;
    }

    &__logo {
      height: 42px;
    }

    .search-block {
      width: 225px;
    }
  }

  .userBox__name {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1300px) {
  .mainHeader {
    &__menu {
      margin: 0px 20px;
    }

    &__toggle {
      margin-right: 16px;
    }

    &__logo {
      height: 38px;
    }

    .search-block {
      width: 190px;
    }
  }

  .userBox__name {
    font-size: 14px;
  }

  .userBox__image {
    height: 30px;
    width: 30px;
    margin-right: 8px;
  }
}

// @media only screen and (max-width: 1024px) {
//   .logout-icon {
//     height: 30px;
//     width: 30px;
//   }

//   .mainHeader__menu {
//     margin: 0px 10px;
//   }
// }

@media only screen and (max-width: 1160px) {
  .mainHeader {
    padding: 8px 22px;

    &__logo {
      height: 35px;
    }

    &__menu {
      display: block;
      margin: 0;
      padding: 20px 0;
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;

      .menuItem {
        padding: 8px 20px;
        min-height: 49px;
        margin: 0;

        &__link {
          font-family: "Roboto", sans-serif;
          font-weight: 500;
          font-size: 17px;
          line-height: 25px;
          color: rgba(0, 0, 0, 0.85);
        }

        &:hover {
          background-color: #ecf6ff !important;

          .menuItem__link {
            color: #1976d2;
          }
        }
      }
    }

    .search-block {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      position: inherit;
      position: absolute;
      top: calc(100% + 1px);
      width: 100%;
      right: 0;
      border-bottom: 1px solid #ddd;
      background: #fff;
      width: 100%;
      max-width: 100%;
      display: none;

      &__icon {
        padding: 0;
        right: 0;
      }

      & > .MuiInputBase-root {
        // position: absolute;
        // left: 0;
        // right: 0;
        // top: calc(100% + 1px);
        // z-index: 15;
        // height: 42px;
        // border-bottom: 3px solid #eee;
        // background: #fff;

        input {
          border: none;
          padding: 8px 26px;
        }
      }
      &.active-search {
        display: flex;
      }
    }
  }

  .userBox {
    margin-right: 12px;

    &__name {
      display: none;
    }

    &__image {
      margin-right: 0;
    }
  }

  .mobile-menu {
    display: block;
  }

  .nav-header {
    display: none;
  }
}

//text
