.max-w-250px {
  margin: 0 auto;
  max-width: 250px;
}

.mb-6 {
  margin-top: 10px;
  margin-bottom: 24px;
}

.tableIconBtn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #1976d2;
}

.meetingPersonBox {
  border-radius: 0;
  margin: 0;
  padding: 5px 10px;
  gap: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  height: 59px;
  img {
    margin: 0;
    border-radius: 50%;
  }
  span {
    display: block;
    white-space: break-spaces;
  }
}

.availabilityStatus {
  display: flex;
  flex-direction: column;
  align-items: self-start;
  flex-wrap: wrap;
  background: #ecf6ff;
  border-radius: 4px;
  padding: 10px;
  max-width: 200px;
  margin: 10px auto;

  span {
    padding: 5px 15px;
    position: relative;
    display: inline-flex;
    align-items: center;
    font-family: "Roboto", sans-serif;
    font-weight: 450;
    font-size: 14px;
    line-height: 20px;
    padding-left: 32px;
    color: rgba(0, 0, 0, 0.85);

    &::before {
      content: "";
      height: 20px;
      width: 20px;
      background: #ffffff;
      border: 1px solid #f5f5f5;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      display: block;
      position: absolute;
      left: 0;
    }

    &.availableBox {
      &::before {
        background: #6bb283;
      }
    }

    &.unAvailableBox {
      &::before {
        background: #ffffff;
      }
    }

    &.fullyBookBox {
      &::before {
        background: #000;
      }
    }

    &.partiallyBookBox {
      &::before {
        background: #096dd9;
      }
    }
  }
}

.availabilityStatusHorizontal {
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 335px;
  margin: 15px auto 0;

  span {
    padding: 5px 22px;
    position: relative;
    display: inline-flex;
    align-items: center;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.85);

    &::before {
      content: "";
      height: 16px;
      width: 16px;
      background: #ffffff;
      border: 1px solid #f5f5f5;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.18);
      border-radius: 2px;
      display: block;
      position: absolute;
      left: 0;
    }

    &.availableBox {
      &::before {
        background: #6bb283;
      }
    }

    &.unAvailableBox {
      &::before {
        background: #ffffff;
      }
    }

    &.fullyBookBox {
      &::before {
        background: #000;
      }
    }

    &.partiallyBookBox {
      &::before {
        background: #096dd9;
      }
    }
  }
}

.m-0 {
  margin: 0 !important;
}

.mb-8 {
  margin-bottom: 24px !important;
}

.innerTabs {
  padding: 0;
  // margin: 32px 0 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  gap: 30px;
  // width: 100%;

  overflow-x: auto;
  // height: 40px;

  &__item {
    // padding-bottom: 5px;

    button {
      font-weight: 600;
      font-size: 18px;
      line-height: 34px;
      display: flex;
      align-items: center;
      text-align: center;
      color: rgba(0, 0, 0, 0.85);
      cursor: pointer;
      position: relative;
      background: transparent;
      border: none;
      box-shadow: none;

      &.active {
        color: #096dd9;
        font-weight: 700;
        border-bottom: 2px solid #3691f3;
        &::after {
          opacity: 100%;
        }
      }
    }
  }
}

.commentListBox {
  max-height: 60vh;
  overflow-y: auto;
  padding-right: 15px;
}

.commentForm {
  position: relative;
  margin-bottom: 20px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding-bottom: 40px;
  textarea {
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    border: none;
    background-color: transparent;
    &:focus-visible {
      outline: none;
    }
    &:focus {
      box-shadow: unset;
    }
  }

  button {
    position: absolute;
    right: 10px;
    bottom: 10px;
    background: #096dd9 !important;
    border: 1px solid #096dd9;
    box-shadow: 0px 2px 0px rgb(0 0 0 / 2%) !important;
    border-radius: 4px;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #fff !important;
    padding: 4px 15px;
    height: 32px;
    transition: 0.3s;
    &:hover {
      background: #ffffff !important;
      border: 1px solid #096dd9;
      color: #096dd9 !important;
    }
  }
  .cancel-btn {
    background: #ffffff !important;
    color: #096dd9 !important;
    &:hover {
      background: #096dd9 !important;
      color: #fff !important;
    }
  }
  .textarea-btn-box {
    position: absolute;
    right: 10px;
    bottom: 10px;
    button {
      position: unset;
      margin-left: 10px;
      right: 10px;
      bottom: 18px;
      background: #096dd9 !important;
      border: 1px solid #d9d9d9;
      box-shadow: 0px 2px 0px rgb(0 0 0 / 2%) !important;
      border-radius: 4px;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      text-align: center;
      color: #ffffff !important;
    }
  }
}

.commentListItem {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 0 26px;
  gap: 8px;

  &__user {
    width: 100%;
    display: flex;
    gap: 10px;

    &-img {
      //height: 32px;
      //width: 32px;
      //flex-grow: 0;
      //flex-shrink: 0;
      //flex-basis: 32px;
      //border-radius: 50%;
      // overflow: hidden;
      // border: 1px solid #ddd;

      img {
        height: 30px;
        width: 30px;
        object-fit: cover;
        padding: 2px;
      }
    }

    &-info {
      width: 100%;
    }

    &-heading {
      display: flex;
      align-items: center;
      gap: 15px;
      margin: 8px 0;
    }

    &-name {
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.85);
      margin: 0;
    }

    &-time {
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.85);
      margin: 0;
    }

    &-comment {
      background: #ecf6ff;
      border-radius: 4px;
      padding: 12px;
      width: 100%;
      margin: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.85);
    }
  }

  &.otherUser {
    .commentListItem__user-comment {
      background: #f5f5f5;
    }
  }

  &.generated {
    .commentListItem__user-comment {
      background: #fff;
      padding: 12px 0;
    }
  }

  &__moreBtn {
    cursor: pointer;
  }
}

.commentList-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  button {
    background: #ffffff;
    border: 1px solid #096dd9;
    box-shadow: 0px 2px 0px rgb(0 0 0 / 2%);
    border-radius: 4px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #096dd9;
    padding: 4px 15px;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      background: #096dd9;
      color: #fff;
    }
  }
}

.singleDatePicker {
  input {
    padding: 10px;
  }

  label {
    transform: translate(14px, 10px) scale(1);
    &.MuiInputLabel-shrink {
      transform: translate(14px, -9px) scale(0.75);
    }
  }

  .MuiButtonBase-root.MuiIconButton-root {
    background-color: transparent !important;
    color: #096dd9 !important;
  }
}

.MuiPickersPopper-paper {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  overflow: hidden;

  .MuiPickersCalendarHeader-root {
    margin: 0;
    background: #096dd9;
    padding: 5px 15px;
    max-height: 100%;
    height: auto;
    box-sizing: border-box;
  }

  .MuiPickersCalendarHeader-label {
    color: #fff;
  }

  .MuiPickersCalendarHeader-labelContainer {
    button {
      color: #fff !important;
    }
  }

  .MuiDayPicker-header {
    margin-top: 8px;

    .MuiTypography-root {
      color: #096dd9 !important;
    }
  }

  .PrivatePickersSlideTransition-root {
    min-height: 220px;
  }
}

.MuiCalendarPicker-root {
  button {
    background-color: transparent !important;
    color: #555 !important;

    &.Mui-selected {
      background-color: #096dd9 !important;
      color: #fff !important;
    }
  }

  .MuiPickersArrowSwitcher-root {
    button {
      color: #fff !important;
    }

    .MuiPickersArrowSwitcher-spacer {
      width: 5px !important;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .catalogHead {
    flex-wrap: wrap !important;
    .MuiTypography-root {
      width: 100%;
    }
  }
}
@media only screen and (max-width: 1299px) {
  .innerTabs {
    gap: 10px;
    margin-right: 20px;

    button {
      font-size: 18px;
      line-height: normal;
      white-space: nowrap;
    }
  }
}

@media only screen and (max-width: 991px) {
  .innerTabs {
    button {
      font-size: 16px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .detailsPageBody {
    .MuiContainer-root {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .commentListBox {
    padding-right: 0;
    max-height: 100%;
  }

  .innerTabs {
    gap: 18px;
    &__item button {
      font-weight: 400;
      font-size: 16px;
      white-space: nowrap;
      line-height: 30px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .commentListItem {
    margin: 0 0 22px;
    gap: 2px;

    &__user {
      gap: 8px;

      &-comment {
        padding: 10px;
        font-size: 14px;
      }

      &-heading {
        gap: 5px;
        flex-direction: column;
        align-items: flex-start;
      }

      &-time {
        font-size: 13px;
      }
    }
  }
}

.e-schedule .e-schedule-toolbar .e-icon-prev::before {
  content: "" !important;
  height: 10px;
  width: 10px;
  border-left: 1px solid #000;
  border-bottom: 1px solid #000;
  transform: rotate(45deg);
  display: block;
  margin-left: 5px;
}
.e-toolbar .e-tbar-btn:hover {
  background: #ccdff1;
}

.e-schedule .e-schedule-toolbar .e-icon-next::before {
  content: "" !important;
  height: 10px;
  width: 10px;
  border-right: 1px solid #000;
  border-top: 1px solid #000;
  transform: rotate(45deg);
  display: block;
  margin-right: 5px;
}
.e-schedule .e-schedule-toolbar .e-icon-down-arrow::before {
  content: "" !important;
  height: 10px;
  width: 10px;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  transform: rotate(45deg);
  display: block;
  margin-right: 5px;
  margin-bottom: 5px;
}

.schedule-control-section {
  .e-schedule {
    .e-timeline-view {
      .e-resource-column-wrap {
        table td {
          padding: 0;
          div {
            width: 100%;
            // height: 100%;
          }
        }
      }
    }
  }
}

.upgradeModal .MuiDialog-paper {
  height: auto !important;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100;
}

.forms-preview {
  position: relative;
  height: 560px;

  .css-si86to-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .error-message {
    position: absolute;
    z-index: 55;
    top: 45%;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .language-DrpDwn {
    position: absolute;
    top: 10px;
    left: 15px;
    z-index: 5;
  }
  .MuiDialog-paper {
    overflow: hidden;
  }
  .giHfzu {
    margin: 0px;
    height: 500px;
    padding: 0px 30px;
    overflow: auto;
  }
  .unjha {
    height: 500px;
    overflow: auto;
    padding: 30px;
  }
  .kiJKRu {
    padding: 15px;
  }
  .react-pdf__message--error{
    position: absolute;
    top: 44%;
    left: 40%;
  }
}
