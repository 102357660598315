.matchScheduleBox {
  .fromGroup {
    margin-top: 50px;
    margin-bottom: 24px;

    .fromGroup-chips .MuiPaper-root {
      min-height: 32px;
      margin: 5px 0;
      margin-right: 8px;
      padding: 4px 10px;
      text-align: center;

      input {
        width: 35px;
        text-align: center;
        padding: 5px;
        height: 24px;
        margin: 0 5px;
        font-family: "Roboto", sans-serif !important;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
      }
    }
  }

  .fromLabel {
    font-family: "Roboto", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
  }

  &-time {
    .fromGroup-chips .MuiPaper-root {
      width: 100%;
      margin: 5px 0;
    }
  }
}

.verticalDivider {
  height: 100%;
  width: 2px;
  background: #f0f0f0;
  margin: 0 auto;
}
