@import "../variables.scss";

.svc-context-container {
  display: flex;
  gap: calcSize(1);
  width: max-content;
}

.svc-context-button {
  display: block;
  box-sizing: border-box;
  background-color: $background;
  border-radius: 50%;
  width: var(--ctr-survey-contextual-button-width, calcSize(6));
  height: var(--ctr-survey-contextual-button-height, calcSize(6));
  padding: var(--ctr-survey-contextual-button-padding-top, calcSize(1.5))
    var(--ctr-survey-contextual-button-padding-right, calcSize(1.5))
    var(--ctr-survey-contextual-button-padding-bottom, calcSize(1.5))
    var(--ctr-survey-contextual-button-padding-left, calcSize(1.5));

  cursor: pointer;
  outline: none;

  .sv-svg-icon {
    width: var(--ctr-button-contextual-button-icon-width, calcSize(3));
    height: var(--ctr-button-contextual-button-icon-height, calcSize(3));
  }

  use {
    fill: $foreground-light;
    transition: fill $creator-transition-duration;
  }
}

.svc-context-button {

  &:hover,
  &:focus {
    use {
      fill: $primary;
    }
  }
}

.svc-context-button--danger {

  &:hover,
  &:focus {
    use {
      fill: $red;
    }
  }
}