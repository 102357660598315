.abaCard {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  height: 100%;

  &__header {
    background: #096dd9;
    border-radius: 4px 4px 0px 0px;
    padding: 15px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__danger {
    background: #EFEFF3;
    border-radius: 4px 4px 0px 0px;
    padding: 15px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #ffffff;
    margin: 0;
  }

  &__title__danger {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #262626;
    margin: 0;
  }

  &__button__danger {
    background: #ffffff !important;
    border: 1px solid #D9D9D9;
    padding: 5px 16px;
    color: #FF0000 !important;
    font-weight: 700;
  }

  &__headBtn {
    color: white;
    padding: 0;
    border-bottom: 1px solid #fff;
    border-radius: 0;
    background: transparent !important;
    padding-bottom: 3px;

    svg {
      height: 16px;
      width: 16px;
    }
  }

  &__body {
    padding: 24px;

    & > div:last-child {
      margin-bottom: 0;

      .detailsCard-part {
        margin-bottom: 0;
      }
    }
  }

  &-lastBtn {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #096dd9 !important;
    background: transparent !important;
    border: none;
    padding: 0;
    margin: 15px 0 0;

    svg {
      margin-left: 0;
    }
  }

  input {
  }
}

.removeBtn {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.uploadCompanyProfile {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  height: 100%;

  &__btn {
    height: 64px;
    padding: 22px 10px;
    background: #f6fbff !important;
    border-radius: 0px 0px 4px 4px;
    width: 100%;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #096dd9 !important;
    box-shadow: none;

    &:hover {
      box-shadow: none !important;
    }
  }

  &__img {
    height: calc(100% - 64px);
    padding: 30px;
    text-align: center;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      max-width: 350px;
      margin: 0 auto;
    }
  }
}

.removeBtn svg {
  height: 22px;
  width: 22px;
  color: #096dd9 !important;
}

.authList {
  & > .MuiGrid-container {
    margin-bottom: 6px;
    &:not(:first-child) {
      .MuiFormLabel-root {
        display: none;
      }
    }
  }
}

.dropdownBox {
  & > :nth-child(3n) {
    padding: 0;
    height: 40px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    width: 100%;
    box-sizing: border-box;
    .css-1fdsijx-ValueContainer {
      padding: 0 10px;
      .css-qbdosj-Input {
        height: 38px;
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
      }
    }
    .css-1u9des2-indicatorSeparator {
      margin: 0;
    }
  }
  & > :nth-child(4n) > div {
    height: 130px;
  }
}

@media only screen and (max-width: 900px) {
  .authList {
    & > .MuiGrid-container {
      margin-bottom: 20px;
      &:not(:first-child) {
        .MuiFormLabel-root {
          display: block;
        }
      }
    }
  }
}
