.ComposeMessage_header {
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    align-items: end;
  }
  .header_firstBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    @media (max-width: 768px) {
      flex-direction: column;
      display: flex;
      align-items: baseline;
      text-align: left;
      gap: 15px;
    }
  }
  .title {
    font-size: 25px;
    font-weight: bold;
    @media (max-width: 570px) {
      font-size: 20px;
    }
  }
}
.composeMessage_content {
  width: 100%;
  display: flex;
  gap: 25px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  .message-actions-1 {
    display: flex;
    text-align: center;
    align-items: center;
    margin-top: 18px;
    gap: 56px;
    .sender-input {
      background: #ffffff;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      .css-16wblaj-MuiInputBase-input-MuiOutlinedInput-input {
        padding: 9.5px 14px;
      }
    }
    // .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    //   padding: 9.5px 14px;
    // }
    .css-1qfpjuc-MuiFormControl-root-MuiTextField-root {
      width: 50%;
    }
  }
  .message-actions-2 {
    display: flex;
    margin-top: 25px;
    width: 100%;
    gap: 30px;
    align-items: center;
    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
      padding: 9.5px 14px;
    }
    .css-qy903u {
      width: 50%;
      min-width: auto;
    }
  }
}
.editor-section {
  width: 70%;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.recipents-search {
  position: relative;
  display: flex;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15) !important;
  border-radius: 4px;
  padding: 10px;
  box-sizing: border-box;
  height: 42px;
  align-items: center;
  margin: 15px 0px 0px 107px;
  gap: 5px;
  overflow-x: auto;
  overflow-y: hidden;
  @media (max-width: 768px) {
    margin: 0;
  }

  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 9.5px 14px;
  }
  .css-1w4vsez-MuiFormControl-root {
    margin: 0px 8px;
  }
}
.textLabel {
  font-weight: bold;
  span {
    font-weight: 400;
    color: #957e7ed6;
    font-size: 16px;
  }
}

.mb-18 {
  margin-bottom: 18px;
}
.mt-18 {
  margin-top: 18px;
}
.message-editor {
  // width: 73%;
  .ql-container {
    height: 370px;
  }
}
.fileSection {
  // margin-top: 48px;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  .selectedIn-file {
    margin-bottom: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .fileTabs {
    display: flex;
    gap: 15px;
    list-style: none;
    padding: 0;
    button {
      font-size: 18px;
      line-height: 34px;
      display: flex;
      align-items: center;
      text-align: center;
      cursor: pointer;
      background: transparent;
      border: none;
    }
    .active {
      color: #1976d2;
      font-weight: 700;
      border-bottom: 2px solid #1976d2;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
}
.tab-Description {
  color: #708090ad;
  font-size: 14px;
}
.fileUpload-block {
  // position: relative;
  text-align: center;
  background: #ecf6ff;
  box-sizing: border-box;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  & > .MuiBox-root {
    position: absolute;
    border: none;
    border-radius: 2px;
    padding: 0;
    top: 32%;
    right: 25%;
    left: 25%;
    & > .MuiBox-root {
      display: none !important;
    }
  }

  .MuiFormControl-root {
    padding: 44px;
  }

  .MuiTypography-root {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: rgb(137 123 123 / 85%);
  }

  .MuiButtonBase-root.MuiButton-root {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0;
    opacity: 0;
  }
}
.attachments {
  box-sizing: border-box;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  padding: 15px;
  height: 373px;
  overflow: auto;
  margin-bottom: 30px;
}
.userInfo-Card {
  background: #ecf6ff;
  padding: 2px;
  text-wrap: nowrap;
  justify-content: center;
}
.searchList {
  box-sizing: border-box;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  padding: 15px;
  background: #ecf6ff;
  margin-left: 107px;
  position: absolute;
  z-index: 9;
  width: calc(100% - 107px);
  max-height: 345px;
  overflow: auto;
  @media (max-width: 768px) {
    width: 100%;
    left: 0;
    margin: 0;
  }
  .userlist {
    padding: 6px;
    text-wrap: nowrap;
    background: #fff;
    margin: 9px 0px;
  }
}
.relative {
  position: relative;
}

.Library-Search {
  .recipents-search {
    margin: 0;
  }
  .searchList {
    margin: 0;
    width: 100%;
  }
}
.searchedFile-List {
  height: 137px;
  overflow: auto;
  .client-fileList {
    list-style: none;
    padding: 0px;
    margin: 5px;
    li {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 3px 0px;
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
