.viewAllPlan {
  font-family: "Roboto", sans-serif;
  display: flex;
  align-items: center;
  color: #096dd9 !important;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
}

.statusChip {
  border-radius: 2px;
  border: 1px solid var(--neutral-5, #d9d9d9);
  background: var(--neutral-2, #fafafa);
  padding: 5px 16px;
  min-width: 90px;
  display: inline-block;
  text-align: center;
  color: rgba(0, 0, 0, 0.85);
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
}

.userPlanCard {
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  &__head {
    padding: 15px ;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-btn {
      color: #000;
      font-family: "Roboto", sans-serif !important;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      padding: 6px;
      border: none;
      border-radius: 4px;
      background: #ecf6ff;
    }
  }
  &__body {
    padding: 15px;
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    &-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
      &-title {
        // font-weight: bold;
        font-size: 16px;
      }
      &-count {
        padding: 5px 10px;
        background-color: #cae4ff;
        border-radius: 5px;
      }
    }
  }
  &__footer {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-btn {
      border-radius: 4px;
      border: 1px solid #096dd9;
      background: #096dd9;
      box-shadow: 0px 2px 0px 0px #096dd9;
      color: #fff;
      text-align: center;
      font-family: "Roboto", sans-serif !important;
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      padding: 4px 10px;
      margin: 0px;
    }
  }
  &__title {
    color: rgba(0, 0, 0, 0.85) !important;
    font-family: "Roboto", sans-serif !important;
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 24px !important;
  }
  &__price {
    color: #3d3c41;
    font-family: "Roboto", sans-serif !important;
    font-size: 26px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.78px;
  }
}
