.colIcon {
    height: 25px;
    width: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
}
.UserManagementTitle {
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 20px !important;
  }