$size-unit: var(--ctr-size-unit, 8px);
$spacing-unit: var(--ctr-spacing-unit, 8px);
$corner-radius-unit: var(--ctr-corner-radius-unit, 8px);
$stroke-unit: var(--ctr-stroke-unit, 1px);

.svc-creator {
  --sjs-spacing-x2: calc(#{$spacing-unit} * 2);
  --sjs-spacing-x3: calc(#{$spacing-unit} * 3);
  --sjs-spacing-x1: calc(#{$spacing-unit} * 1);
  --sjs-spacing-x05: calc(#{$spacing-unit} * 0.5);
  --sjs-spacing-x025: calc(#{$spacing-unit} * 0.25);
  --sjs-spacing-x0125: calc(#{$spacing-unit} * 0.125);
  --sjs-spacing-x150: calc(#{$spacing-unit} * 1.5);
  --sjs-spacing-x250: calc(#{$spacing-unit} * 2.5);
  --sjs-spacing-x4: calc(#{$spacing-unit} * 4);
  --sjs-spacing-x350: calc(#{$spacing-unit} * 3.5);
  --sjs-spacing-x5: calc(#{$spacing-unit} * 5);
  --sjs-spacing-x6: calc(#{$spacing-unit} * 6);
  --sjs-spacing-x7: calc(#{$spacing-unit} * 7);
  --sjs-spacing-x8: calc(#{$spacing-unit} * 8);
  --sjs-spacing-x9: calc(#{$spacing-unit} * 9);
  --sjs-spacing-x10: calc(#{$spacing-unit} * 10);
  --sjs-spacing-x11: calc(#{$spacing-unit} * 11);
  --sjs-spacing-x12: calc(#{$spacing-unit} * 12);
  --sjs-spacing-x13: calc(#{$spacing-unit} * 13);
  --sjs-spacing-x14: calc(#{$spacing-unit} * 14);
  --sjs-spacing-x15: calc(#{$spacing-unit} * 15);
  --sjs-spacing-x16: calc(#{$spacing-unit} * 16);
  --sjs-spacing-x075: calc(#{$spacing-unit} * 0.75);
  --sjs-spacing-x125: calc(#{$spacing-unit} * 1.25);
  --sjs-spacing-x1050: calc(#{$spacing-unit} * 10.5);
  --sjs-spacing-x0375: calc(#{$spacing-unit} * 0.375);
  --sjs-spacing-x850: calc(#{$spacing-unit} * 8.5);
  --sjs-spacing-x025n: calc(#{$spacing-unit} * -0.25);
  --sjs-spacing-x3n: calc(#{$spacing-unit} * -3);
  --sjs-spacing-x4n: calc(#{$spacing-unit} * -4);
  --sjs-spacing-x05n: calc(#{$spacing-unit} * -0.5);
  --sjs-spacing-x1n: calc(#{$spacing-unit} * -1);
  --sjs-spacing-x150n: calc(#{$spacing-unit} * -1.5);
  --sjs-spacing-x2n: calc(#{$spacing-unit} * -2);
  --sjs-spacing-x5n: calc(#{$spacing-unit} * -5);
  --sjs-spacing-x6n: calc(#{$spacing-unit} * -6);
  --sjs-spacing-x7n: calc(#{$spacing-unit} * -7);
  --sjs-spacing-x8n: calc(#{$spacing-unit} * -8);
  --sjs-corner-radius-x0125: calc(#{$corner-radius-unit} * 0.125);
  --sjs-corner-radius-x025: calc(#{$corner-radius-unit} * 0.25);
  --sjs-corner-radius-x05: calc(#{$corner-radius-unit} * 0.5);
  --sjs-corner-radius-x075: calc(#{$corner-radius-unit} * 0.75);
  --sjs-corner-radius-x1: calc(#{$corner-radius-unit} * 1);
  --sjs-corner-radius-x125: calc(#{$corner-radius-unit} * 1.25);
  --sjs-corner-radius-x150: calc(#{$corner-radius-unit} * 1.5);
  --sjs-corner-radius-x2: calc(#{$corner-radius-unit} * 2);
  --sjs-corner-radius-x250: calc(#{$corner-radius-unit} * 2.5);
  --sjs-corner-radius-x3: calc(#{$corner-radius-unit} * 3);
  --sjs-corner-radius-x350: calc(#{$corner-radius-unit} * 3.5);
  --sjs-corner-radius-x4: calc(#{$corner-radius-unit} * 4);
  --sjs-corner-radius-x5: calc(#{$corner-radius-unit} * 5);
  --sjs-corner-radius-x6: calc(#{$corner-radius-unit} * 6);
  --sjs-corner-radius-x7: calc(#{$corner-radius-unit} * 7);
  --sjs-corner-radius-x8: calc(#{$corner-radius-unit} * 8);
  --sjs-corner-radius-round: calc(#{$corner-radius-unit} * 128);
  --sjs-corner-radius-x0375: calc(#{$corner-radius-unit} * 0.375);
  --sjs-stroke-x1: calc(#{$stroke-unit} * 1);
  --sjs-stroke-x2: calc(#{$stroke-unit} * 2);
  --sjs-stroke-x3: calc(#{$stroke-unit} * 3);
  --sjs-stroke-x4: calc(#{$stroke-unit} * 4);
  --sjs-stroke-x6: calc(#{$stroke-unit} * 6);
  --sjs-stroke-x8: calc(#{$stroke-unit} * 8);
  --sjs-size-x0125: calc(#{$size-unit} * 0.125);
  --sjs-size-x025: calc(#{$size-unit} * 0.25);
  --sjs-size-x05: calc(#{$size-unit} * 0.5);
  --sjs-size-x075: calc(#{$size-unit} * 0.75);
  --sjs-size-x1: calc(#{$size-unit} * 1);
  --sjs-size-x125: calc(#{$size-unit} * 1.25);
  --sjs-size-x150: calc(#{$size-unit} * 1.5);
  --sjs-size-x2: calc(#{$size-unit} * 2);
  --sjs-size-x250: calc(#{$size-unit} * 2.5);
  --sjs-size-x3: calc(#{$size-unit} * 3);
  --sjs-size-x350: calc(#{$size-unit} * 3.5);
  --sjs-size-x4: calc(#{$size-unit} * 4);
  --sjs-size-x5: calc(#{$size-unit} * 5);
  --sjs-size-x6: calc(#{$size-unit} * 6);
  --sjs-size-x7: calc(#{$size-unit} * 7);
  --sjs-size-x8: calc(#{$size-unit} * 8);
  --sjs-size-x9: calc(#{$size-unit} * 9);
  --sjs-size-x10: calc(#{$size-unit} * 10);
  --sjs-size-x11: calc(#{$size-unit} * 11);
  --sjs-size-x12: calc(#{$size-unit} * 12);
  --sjs-size-x13: calc(#{$size-unit} * 13);
  --sjs-size-x14: calc(#{$size-unit} * 14);
  --sjs-size-x15: calc(#{$size-unit} * 15);
  --sjs-size-x16: calc(#{$size-unit} * 16);
  --sjs-size-x550: calc(#{$size-unit} * 5.5);
  --sjs-size-max: 10000px;
  --sjs-size-x17: calc(#{$size-unit} * 17);
  --sjs-size-x18: calc(#{$size-unit} * 18);
  --sjs-size-x64: calc(#{$size-unit} * 64);
  --sjs-size-x90: calc(#{$size-unit} * 90);
  --sjs-size-x80: calc(#{$size-unit} * 80);
  --sjs-size-x40: calc(#{$size-unit} * 40);
  --sjs-size-x32: calc(#{$size-unit} * 32);
  --sjs-size-x20: calc(#{$size-unit} * 20);
  --sjs-size-x86: calc(#{$size-unit} * 86);
  --sjs-size-x60: calc(#{$size-unit} * 60);
  --sjs-size-x28: calc(#{$size-unit} * 28);
  --sjs-size-x50: calc(#{$size-unit} * 50);
}
