.form {
  margin-top: 60px;
  .MuiGrid-root {
    margin-bottom: 30px;
    .MuiTypography-root {
      font-size: 13px !important;
      color: #ff2222 !important;
      margin: 2px 0 !important;
    }
  }
  .MuiInput-root {
    width: 100%;
    &:before {
      border-bottom: 1px solid #d6d5d9 !important;
    }
    &:after {
      border-bottom: 1px solid #096dd9 !important;
    }
    input {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }
  .show-password {
    color: #87858e;
    font-family: "lato";
    font-size: 10px;
    cursor: pointer;
  }
  .forgot-password {
    color: #096dd9;
    font-size: 14px;
    text-decoration: none;
    font-family: "lato";
  }
}

.auth-container {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 40%;
  display: none;
}

.descope-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.sidepanel {
  background-color: #f6fbff;
  width: 35%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px;
}

.sodepanel-slogan {
  width: 100%;
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.slogan {
  font-size: 40px;
  font-weight: 700;
  display: flex;
  align-items: flex-start;
  flex-direction: column-reverse;
}

.slogan span {
  color: #8c946e;
}

@media only screen and (max-width: 770px) {
  .sidepanel {
    display: none;
  }
  .logo {
    display: block;
  }
}

@media only screen and (max-height: 750px) {
  .logo {
    margin-top: 95px;
  }
}
