.customStepper {
  display: flex;
  align-items: center;
  justify-content: center;

  &__button {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(0, 0, 0, 0.2509803922);
    height: 48px;
    width: 48px;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;

    span {
      font-weight: 500;
      margin: 0;
      font-size: 24px;
      line-height: 32px;
    }

    &.active {
      background: #096dd9;
      color: #fff;
    }
  }

  &__btnDivider {
    background: #d9d9d9;
    height: 3px;
    width: 85px;
  }
}

.importCard {
  .fileUploadBlock .MuiFormControl-root {
    height: 300px;
  }
}

.backHead {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.bottomButtons {
  margin-top: 45px;
  text-align: right;
}

.fileUploadBlock-data {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .selectedIn-fileBox {
    margin-top: 40px;

    h2 {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.85);
      margin: 0 0 12px;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #666666;
      margin: 0 0 25px;
    }
  }

  &__btn {
    text-align: right;
  }
}

.importCard-module {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 24px;
  margin: 20px 0;

  &__btn {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
}

.importSmallCard {
  background: #ecf6ff;
  border-radius: 4px;
  padding: 12px;
  text-align: center;

  &__title {
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.85);
    margin: 0;
  }

  &__count {
    font-weight: 500;
    font-size: 36px;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.85);
    margin: 0 0 8px;
  }
}

.MuiTooltip-tooltip {
  background: #f5f5f5;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 13px;
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.85);
  position: relative;

  p {
    margin: 8px 0 20px;
  }
}

.MuiTooltip-popper[data-popper-placement="right"] {
  .MuiTooltip-tooltip::before {
    content: "";
    top: 50%;
    background: #f5f5f5;
    height: 12px;
    width: 12px;
    display: block;
    position: absolute;
    left: -5px;
    transform: translateY(-50%) rotate(45deg);
  }
}

.toolTipBtn {
  color: white;
  background: transparent !important;
  border: none;
  min-width: 0;
  padding: 5px;
  margin-left: 5px;

  svg {
    height: 17px;
    width: 17px;
  }
}

.contentModel-data {
  max-width: 600px;
  width: 100%;
  margin: 0 auto;

  h6 {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #096dd9;
    margin: 0;
  }

  p {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.85);
    margin: 24px 0 0;
  }
}

@media only screen and (max-width: 991px) {
  .importCard-module {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 18px;

    &__btn {
      grid-column-start: 2;
      grid-column-end: 4;
    }
  }
}

@media only screen and (max-width: 767px) {
  .importCard-module {
    grid-template-columns: 1fr 1fr;

    &__btn {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }

  .importCard .fileUploadBlock .MuiFormControl-root {
    height: 200px;
  }

  .fileUploadBlock-data .selectedIn-fileBox {
    margin-top: 10px;
  }

  .customStepper {
    &__button {
      font-size: 22px;
      height: 38px;
      width: 38px;

      span {
        font-weight: 500;
        margin: 0;
        font-size: 22px;
        line-height: 32px;
      }
    }

    &__btnDivider {
      width: 50px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .contentModel-data {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;

    h6 {
      font-size: 22px;
      line-height: 28px;
    }

    p {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .importCard-module {
    grid-template-columns: 1fr;

    &__btn {
      grid-column-start: 1;
      grid-column-end: 2;
    }
  }
}
.export-btn {
  // width: 95%;
  display: flex;
  gap: 12px;
  /* float: right; */
  flex-direction: row-reverse;
  position: absolute;
  top: 13%;
  right: 33px;
}
.import-card {
  .smallCardIcons {
    background-color: #fff;
    margin-bottom: 0px;
    position: absolute;
    top: 35%;
    right: 43%;
    svg {
      height: 65px;
      width: 65px;
    }
  }
}
.importFileUplaod-block {
  text-align: center;
  & > .MuiBox-root {
    border: none;
    border-radius: 2px;
    padding: 0;
    & > .MuiBox-root {
      display: none !important;
    }
  }
  .css-1q8v7ak-MuiSvgIcon-root {
    display: none;
  }
  .MuiFormControl-root {
    padding: 68px;
  }
  .css-1fel157 input {
    cursor: pointer;
  }
  .MuiTypography-root {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: rgb(137 123 123 / 85%);
  }

  .MuiButtonBase-root.MuiButton-root {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0;
    opacity: 0;
  }
}
.review-policy {
  .field-part {
    margin-bottom: 18px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 7px 14px;
  }
}
.pendingReview__title {
  font-size: 19px;
  font-weight: 600;
  margin: 35px 0px 15px 2px;
}
.history-status {
  border-radius: 5px;
  padding: 3px 10px;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  font-weight: 400;
  width: fit-content;
}
.Pending {
  color: #000;
  background-color: #c5b37f1f;
  border: 1px solid #e8a02d;
}
.Complete {
  color: #000;
  border: 1px solid #3259ab;
  background-color: #e9f7ff;
}

.Failed {
  color: #000;
  border: 1px solid red;
  background-color: #ffc1c154;
}
.Error {
  color: #000;
  border: 1px solid red;
  background-color: #ffc1c154;
}
.history-modal {
  .MuiDialog-paper {
    width: 100%;
    text-align: center;
    max-width: 700px;
  }

  .header {
    background: #096dd9;
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 12px 23px;
    position: sticky;
    top: 0;
    bottom: 10px;
    z-index: 11;
    float: right;
  }
  .content {
    padding: 30px;
    .message-title {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 10px;
    }
    .message-description {
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .footer {
    border-top: 1px solid #80808033;
    .button {
      padding: 2px 22px;
    }
  }
}
.header {
  background: #096dd9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  position: sticky;
  top: 0;
  bottom: 10px;
  z-index: 11;
}
.tools-Dialog {
  .MuiDialog-paper {
    max-width: 500px;
    width: 100%;
    height: auto;
  }
  .content {
    padding: 50px;
    text-align: center;
  }
  .sse-Message {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 15px;
  }
  .close-btn {
  }
}

.tab {
  margin: 0px 56px;
  padding: 5px 20px;
}

.formGroup-login {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.m-20 {
  margin: 0px 20px;
}
.SyncRequest {
  .e-checkbox-wrapper .e-frame,
  .e-css.e-checkbox-wrapper .e-frame {
    width: 20px; /* Adjust the width */
    height: 20px;
    line-height: 17px;
  }

  /* Adjust the checkbox cell padding to center the larger checkbox */
  .e-grid .e-rowcell.e-selectionbackground {
    padding: 5px; /* Adjust padding as needed */
  }
}
.header-syncRequest {
  display: flex;
  justify-content: space-between;
  .referesh {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 8px;
    .refresh-title {
      font-size: 1.6rem;
      font-weight: 700;
    }
  }
  .sync-description {
    font-size: 13px;
    font-weight: 600;
    color: #000000ba;
    margin-bottom: 8px;
  }
}
.filter-syncRequest {
  display: flex;
  align-items: center;
  .css-4evdan-MuiFormLabel-root-MuiInputLabel-root {
    transform: translate(5px, -17px) scale(0.75);
  }
  .css-4evdan-MuiFormLabel-root-MuiInputLabel-root {
    font-weight: 500;
  }
}
.review {
  .MuiDialog-paper {
    max-width: 800px;
    width: 100%;
    height: auto;
    margin: 15px;
  }
  ._GzYRV {
    max-width: 250px;
  }
}

@media only screen and (max-width: 1024px) {
  .export-btn {
    top: 9%;
  }
  .import-card {
    .smallCardIcons {
      top: 22%;
      right: 45%;
    }
  }
}
@media only screen and (max-width: 620px) {
  .export-btn {
    position: unset;
    margin: 10px;
  }
}
.confirmExport {
  .agree-checkBox {
    .css-j204z7-MuiFormControlLabel-root {
      margin: 15px 0px 0px 0px;
    }
  }
  .MuiDialogActions-root {
    justify-content: flex-end;
    padding: 7px 20px;
  }
  .MuiDialogContent-root {
    p {
      font-size: 16px;
      font-weight: inherit;
    }
    text-align: start;
  }
}
