@import "../../variables.scss";

.svc-designer-header .sd-container-modern__title {
  gap: 0;
  column-gap: calcSize(6);
}

.svc-logo-image {
  position: relative;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  padding: 0;
  cursor: pointer;
  max-width: 50%;

  .sd-logo__image {
    max-width: 100%;
  }

  .svc-image-item-value-controls {
    display: block;
    top: calcSize(2.5);
    right: calcSize(1.5);
  }
}

.svc-logo-image__loading {
  width: calcSize(8);
  height: calcSize(8);
  padding: calcSize(1);
  box-sizing: border-box;
  margin-right: calcSize(-2);
}

.svc-logo-image-placeholder {
  @include ctrLargeFont;
  color: $foreground-light;
  opacity: var(--ctr-survey-header-logo-placeholder-icon-opacity, 0.25);
  white-space: nowrap;
  word-break: keep-all;
  padding: 0;
  width: calcSize(8);
  height: calcSize(8);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: calcSize(-2);
  border-radius: var(--ctr-survey-header-logo-placeholder-corner-radius, calcSize(4));
  transition: background-color $creator-transition-duration, opacity $creator-transition-duration;

  &:hover {
    background-color: var(--ctr-survey-header-logo-placeholder-background-color-hovered, $primary-light);
    opacity: unset;

    use {
      fill: var(--ctr-survey-header-logo-placeholder-icon-color-hovered, $primary);
    }
  }

  svg {
    width: var(--ctr-survey-header-logo-placeholder-icon-width, calcSize(6));
    height: var(--ctr-survey-header-logo-placeholder-icon-height, calcSize(6));
  }
}

.svc-logo-image-placeholder use {
  transition: fill $creator-transition-duration;
  fill: var(--ctr-survey-header-logo-placeholder-icon-color, $foreground-light);
}

.svc-logo-image-container {
  position: relative;

  .sd-logo {
    margin: 0;
  }
}

.svc-logo-image-controls {
  opacity: 0;
  width: max-content;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.svc-logo-image-container {
  cursor: default;
}

.svc-logo-image-container:not(.svc-logo-image-container--editable) {
  .svc-logo-image-controls {
    display: none;
  }
}

.svc-logo-image-container--editable:focus-within,
.svc-logo-image-container--editable:hover {
  .svc-logo-image-controls {
    opacity: 1;
  }
}